<template>
    <div>
        <div v-if="loading" id="preloader">
            <div id="status">&nbsp;</div>
         </div>
    
       <div class="pos-f-t">
    
        <nav class="navbar navbar-dark bg-dark">
          <a class="navbar-brand custom-padding-left3" href="https://www.tpdynamics.com.au/">
            <img src="/tp-logo-white.png" width="60%"  alt="Dynamics-image">
          </a>
          <div class="custom-nav-button custom-padding-right5">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
    
                <span class="icon-bar first-bar"></span>
                <span class="icon-bar"></span>
    
            </button>
          </div>
    
        </nav>
    
        <div class="collapse" id="navbarToggleExternalContent">
          <div class="top-nav-list-wrap">
            <div class="container-fluid">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <div class="home-top-nav-list">
                      <div class="top-nav-list-head-wrap">
                        <a ><h5 class="paraf-1">Company</h5></a>
                      </div>
                      <ul class="pl-0">
                        <li><a href="https://www.tpdynamics.com.au/overview">Overview</a></li>
                        <li><a href="https://www.tpdynamics.com.au/history">History</a></li>
                         <li><a href="https://www.tpdynamics.com.au/philosophy">Our Philosophy</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="home-top-nav-list">
                      <div class="top-nav-list-head-wrap">
                        <a href="https://www.tpdynamics.com.au/team"><h5 class="paraf-1">Team</h5></a>
                      </div>
                      <ul class="pl-0">
                        <li><a href="https://www.tpdynamics.com.au/team#family-full-wrap" id="header-devolop">Development</a></li>
                        <li><a href="https://www.tpdynamics.com.au/team_construct#family-full-wrap" id="header-construct">Construction</a></li>
                        <li><a href="https://www.tpdynamics.com.au/team_civil#family-full-wrap" id="header-civil">Civil</a></li>
                        <li><a href="https://www.tpdynamics.com.au/team_finance#family-full-wrap" id="header-finance">Finance</a></li>
                        <li><a href="https://www.tpdynamics.com.au/team_sales#family-full-wrap" id="header-sales">Sales & Marketing</a></li>
    
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="home-top-nav-list">
                      <div class="top-nav-list-head-wrap">
                        <a href="https://www.tpdynamics.com.au/projectsAll"><h5 class="paraf-1">Projects</h5></a>
                      </div>
                      <ul class="pl-0">
                        <li><a href="https://www.tpdynamics.com.au/projects_commercial">Commercial </a></li>
                        <li><a href="https://www.tpdynamics.com.au/project_residential">Residential</a></li>
    
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="home-top-nav-list">
                      <div class="top-nav-list-head-wrap">
                        <a href="https://www.tpdynamics.com.au/news"><h5 class="paraf-1">News&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5></a>
                      </div>
    
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="home-top-nav-list">
                      <div class="top-nav-list-head-wrap bottom-heading-nav nav-contact">
                        <a href="https://www.tpdynamics.com.au/contact"><h5 class="paraf-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contact</h5></a>
                      </div>
    
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="home-top-nav-list">
                      <div class="top-nav-list-head-wrap">
                        <h5 class="paraf-1"></h5>
                      </div>
    
                    </div>
                  </div>
                </div>
    
    
    
              </div>
            </div>
          </div>
    
        </div>
    
      </div>
    
    <div id="smooth-body">
   
            <canvas ref="canvas"></canvas>

            <img src="/animation_640_l3k1n5d3.gif"  alt="Dynamics-image" class="scroll-down-image" :style="{display: frameIndex >=36 ? 'none' : 'block'}">
         
    
       <div class="text" v-if="!mobile">

         <!-- heading 01 -->

        <h2 class="heading-01" :style="{opacity: frameIndex >=1 && frameIndex <= 4 ? 1 : 0, position: frameIndex >= 1 && frameIndex <= 4 ? 'absolute' : 'static',top: frameIndex >= 1 && frameIndex <= 4 ? '106.3%' : 'auto'}">Canberra Central</h2>

        <h2 class="heading-01" :style="{opacity: frameIndex >= 5 && frameIndex <= 15 ? 1 : 0, position: frameIndex >= 5 && frameIndex <= 15 ? 'fixed' : 'static',top: frameIndex >= 5 && frameIndex <= 15 ? '52%' : 'auto'}">Canberra Central</h2>

        <h2 class="heading-01" :style="{opacity: frameIndex >= 16 && frameIndex <= 26 ? 1 : 0, position: frameIndex >= 16 && frameIndex <= 26 ? 'absolute' : 'static',top: frameIndex >= 16 && frameIndex <= 26 ? '227.3%' : 'auto'}">Canberra Central</h2>


        <!-- heading 02 -->
        
        <h2 class="heading-02" :style="{opacity: frameIndex >=49 && frameIndex <= 52 ? 1 : 0, position: frameIndex >= 49 && frameIndex <= 52 ? 'absolute' : 'static',top: frameIndex >= 49 && frameIndex <= 52 ? '684.9%' : 'auto'}">Northbourne Avenue</h2>

        <h2 class="heading-02" :style="{opacity: frameIndex >= 53 && frameIndex <= 114 ? 1 : 0, position: frameIndex >= 53 && frameIndex <= 114 ? 'fixed' : 'static',top: frameIndex >= 53 && frameIndex <= 114 ? '48%' : 'auto'}">Northbourne Avenue</h2>

        <h2 class="heading-02" :style="{opacity: frameIndex >= 115 && frameIndex <= 120 ? 1 : 0, position: frameIndex >= 115 && frameIndex <= 120 ? 'absolute' : 'static',top: frameIndex >= 115 && frameIndex <= 120 ? '1432.9%' : 'auto'}">Northbourne Avenue</h2>

        <!-- heading 03 -->

        <h2 class="heading-03" :style="{opacity: frameIndex >=183 && frameIndex <= 187 ? 1 : 0, position: frameIndex >= 183 && frameIndex <= 187 ? 'absolute' : 'static',top: frameIndex >= 183 && frameIndex <= 187 ? '2335%' : 'auto'}">The newly built state of the art Yowani Clubhouse</h2>

        <h2 class="heading-03" :style="{opacity: frameIndex >= 188 && frameIndex <= 189 ? 1 : 0, position: frameIndex >= 188 && frameIndex <= 189 ? 'fixed' : 'static',top: frameIndex >= 188 && frameIndex <= 189 ? '48%' : 'auto'}">The newly built state of the art Yowani Clubhouse</h2>

        <h2 class="heading-03" :style="{opacity: frameIndex >=190 && frameIndex <= 192 ? 1 : 0, position: frameIndex >= 190 && frameIndex <= 192 ? 'absolute' : 'static',top: frameIndex >= 190 && frameIndex <= 192 ? '2342%' : 'auto'}">The newly built state of the art Yowani Clubhouse</h2>


        <!-- heading 04 -->

        <h2 class="heading-04" :style="{opacity: frameIndex >=193 && frameIndex <= 197 ? 1 : 0, position: frameIndex >= 193 && frameIndex <= 197 ? 'absolute' : 'static',top: frameIndex >= 193 && frameIndex <= 197 ? '2460%' : 'auto'}">Stage one of the Yowani redevelopment</h2>

        <h2 class="heading-04" :style="{opacity: frameIndex >= 198 && frameIndex <= 199 ? 1 : 0, position: frameIndex >= 198 && frameIndex <= 199 ? 'fixed' : 'static',top: frameIndex >= 198 && frameIndex <= 199 ? '48%' : 'auto'}">Stage one of the Yowani redevelopment</h2>

        <h2 class="heading-04" :style="{opacity: frameIndex >=200 && frameIndex <= 201 ? 1 : 0, position: frameIndex >= 200 && frameIndex <= 201 ? 'absolute' : 'static',top: frameIndex >= 200 && frameIndex <= 201 ? '2472%' : 'auto'}">Stage one of the Yowani redevelopment</h2>


        <!-- heading 05 -->

        <h2 class="heading-05" :style="{opacity: frameIndex >=203 && frameIndex <= 207 ? 1 : 0, position: frameIndex >= 203 && frameIndex <= 207 ? 'absolute' : 'static',top: frameIndex >= 203 && frameIndex <= 207 ? '2580%' : 'auto'}">Stage two of the Yowani redevelopment</h2>
         
        <h2 class="heading-05" :style="{opacity: frameIndex >= 208 && frameIndex <= 210 ? 1 : 0, position: frameIndex >= 208 && frameIndex <= 210 ? 'fixed' : 'static',top: frameIndex >= 208 && frameIndex <= 210 ? '48%' : 'auto'}">Stage two of the Yowani redevelopment</h2>

        <h2 class="heading-05" :style="{opacity: frameIndex >= 211 && frameIndex <= 214 ? 1 : 0, position: frameIndex >= 211 && frameIndex <= 214 ? 'absolute' : 'static',top: frameIndex >= 211 && frameIndex <= 214 ? '2598%' : 'auto'}">Stage two of the Yowani redevelopment</h2>
       
        <!-- heading 06 -->

        <h2 class="heading-06" :style="{opacity: frameIndex >=215 && frameIndex <= 219 ? 1 : 0, position: frameIndex >= 215 && frameIndex <= 219 ? 'absolute' : 'static',top: frameIndex >= 215 && frameIndex <= 219 ? '2730%' : 'auto'}">Final stage of the Yowani redevelopment</h2>

        <h2 class="heading-06" :style="{opacity: frameIndex >= 220 && frameIndex <= 240 ? 1 : 0, position: frameIndex >= 220 && frameIndex <= 240 ? 'fixed' : 'static',top: frameIndex >= 220 && frameIndex <= 240 ? '48%' : 'auto'}">Final stage of the Yowani redevelopment</h2>

        <h2 class="heading-06" :style="{opacity: frameIndex >=241 && frameIndex <= 244 ? 1 : 0, position: frameIndex >= 241 && frameIndex <= 244 ? 'absolute' : 'static',top: frameIndex >= 241 && frameIndex <= 244 ? '2969%' : 'auto'}">Final stage of the Yowani redevelopment</h2>


        <!-- heading 07 -->

        <h2 class="heading-07" :style="{opacity: frameIndex >=245 && frameIndex <= 249 ? 1 : 0, position: frameIndex >= 245 && frameIndex <= 249 ? 'absolute' : 'static',top: frameIndex >= 245 && frameIndex <= 249 ? '3095%' : 'auto'}">Proposed 68 mixed used apartments with basement parking and community facilities</h2>

        <h2 class="heading-07" :style="{opacity: frameIndex >= 250 && frameIndex <= 268 ? 1 : 0, position: frameIndex >= 250 && frameIndex <= 268 ? 'fixed' : 'static',top: frameIndex >= 250 && frameIndex <= 268 ? '48%' : 'auto'}">Proposed 68 mixed used apartments with basement parking and community facilities</h2>

        <h2 class="heading-07" :style="{opacity: frameIndex >=269 && frameIndex <= 274 ? 1 : 0, position: frameIndex >= 269 && frameIndex <= 274 ? 'absolute' : 'static',top: frameIndex >= 269 && frameIndex <= 274 ? '3307%' : 'auto'}">Proposed 68 mixed used apartments with basement parking and community facilities</h2>

        


        <!-- heading 08 -->

        <h2 class="heading-08" :style="{opacity: frameIndex >=275 && frameIndex <= 279 ? 1 : 0, position: frameIndex >= 275 && frameIndex <= 279 ? 'absolute' : 'static',top: frameIndex >= 275 && frameIndex <= 279 ? '3448%' : 'auto'}">Proposed 75 apartments with golf course views as well as 2 levels of basement parking</h2>

        <h2 class="heading-08" :style="{opacity: frameIndex >= 280 && frameIndex <= 290 ? 1 : 0, position: frameIndex >= 280 && frameIndex <= 290 ? 'fixed' : 'static',top: frameIndex >= 280 && frameIndex <= 290 ? '48%' : 'auto'}">Proposed 75 apartments with golf course views as well as 2 levels of basement parking</h2>

        <h2 class="heading-08" :style="{opacity: frameIndex >=291 && frameIndex <= 295 ? 1 : 0, position: frameIndex >= 291 && frameIndex <= 295 ? 'absolute' : 'static',top: frameIndex >= 291 && frameIndex <= 295 ? '3569%' : 'auto'}">Proposed 75 apartments with golf course views as well as 2 levels of basement parking</h2>

       


        <!-- heading 09 -->

        <h2 class="heading-09" :style="{opacity: frameIndex >=296 && frameIndex <= 299 ? 1 : 0, position: frameIndex >= 296 && frameIndex <= 299 ? 'absolute' : 'static',top: frameIndex >= 296 && frameIndex <= 299 ? '3700%' : 'auto'}">Proposed 94 dwellings (townhouses and apartments combined) great aspects and views of the golf course as well as 2 levels of basement parking</h2>


        <h2 class="heading-09" :style="{opacity: frameIndex >= 300 && frameIndex <= 310 ? 1 : 0, position: frameIndex >= 300 && frameIndex <= 310 ? 'fixed' : 'static',top: frameIndex >= 300 && frameIndex <= 310 ? '44%' : 'auto'}">Proposed 94 dwellings (townhouses and apartments combined) great aspects and views of the golf course as well as 2 levels of basement parking</h2>



        <h2 class="heading-09" :style="{opacity: frameIndex >=311 && frameIndex <= 315 ? 1 : 0, position: frameIndex >= 311 && frameIndex <= 315 ? 'absolute' : 'static',top: frameIndex >= 311 && frameIndex <= 315 ? '3802%' : 'auto'}">Proposed 94 dwellings (townhouses and apartments combined) great aspects and views of the golf course as well as 2 levels of basement parking</h2>


        

        <!-- heading 10 -->

        <h2 class="heading-10" :style="{opacity: frameIndex >=316 && frameIndex <= 319 ? 1 : 0, position: frameIndex >= 316 && frameIndex <= 319 ? 'absolute' : 'static',top: frameIndex >= 316 && frameIndex <= 319 ? '3940%' : 'auto'}">(Combined dwelling types, townhouses and apartments) more details soon to be released.</h2>

        <h2 class="heading-10" :style="{opacity: frameIndex >= 320 && frameIndex <= 328 ? 1 : 0, position: frameIndex >= 320 && frameIndex <= 328 ? 'fixed' : 'static',top: frameIndex >= 320 && frameIndex <= 328 ? '44%' : 'auto'}">(Combined dwelling types, townhouses and apartments) more details soon to be released.</h2>

        <h2 class="heading-10" :style="{opacity: frameIndex >=329 && frameIndex <= 334 ? 1 : 0, position: frameIndex >= 329 && frameIndex <= 334 ? 'absolute' : 'static',top: frameIndex >= 329 && frameIndex <= 334 ? '4034%' : 'auto'}">(Combined dwelling types, townhouses and apartments) more details soon to be released.</h2>

        

        <!-- heading 11 -->

        <h2 class="heading-11" :style="{opacity: frameIndex >=335 && frameIndex <= 340 ? 1 : 0, position: frameIndex >= 335 && frameIndex <= 340 ? 'absolute' : 'static',top: frameIndex >= 335 && frameIndex <= 340 ? '4190%' : 'auto'}">Proposed 5 storey community use, retail and commercial office building located opposite the light rail to the city.</h2>

        <h2 class="heading-11" :style="{opacity: frameIndex >= 341 && frameIndex <= 342 ? 1 : 0, position: frameIndex >= 341 && frameIndex <= 342 ? 'fixed' : 'static',top: frameIndex >= 341 && frameIndex <= 342 ? '44%' : 'auto'}">Proposed 5 storey community use, retail and commercial office building located opposite the light rail to the city.</h2>

        <h2 class="heading-11" :style="{opacity: frameIndex >=343 && frameIndex <= 346 ? 1 : 0, position: frameIndex >= 343 && frameIndex <= 346 ? 'absolute' : 'static',top: frameIndex >= 343 && frameIndex <= 346 ? '4198%' : 'auto'}">Proposed 5 storey community use, retail and commercial office building located opposite the light rail to the city.</h2>
     
    
      </div>


      
      <div class="text" v-if="mobile">
        <!-- heading 01 -->

       <h2 class="heading-01" :style="{opacity: frameIndex >=1 && frameIndex <= 4 ? 1 : 0, position: frameIndex >= 1 && frameIndex <= 4 ? 'absolute' : 'static',top: frameIndex >= 1 && frameIndex <= 4 ? '115.3%' : 'auto'}">Canberra Central</h2>

       <h2 class="heading-01" :style="{opacity: frameIndex >= 5 && frameIndex <= 15 ? 1 : 0, position: frameIndex >= 5 && frameIndex <= 15 ? 'fixed' : 'static',top: frameIndex >= 5 && frameIndex <= 15 ? '52%' : 'auto'}">Canberra Central</h2>

       <h2 class="heading-01" :style="{opacity: frameIndex >= 16 && frameIndex <= 26 ? 1 : 0, position: frameIndex >= 16 && frameIndex <= 26 ? 'absolute' : 'static',top: frameIndex >= 16 && frameIndex <= 26 ? '237.3%' : 'auto'}">Canberra Central</h2>


       <!-- heading 02 -->
       
       <h2 class="heading-02" :style="{opacity: frameIndex >=49 && frameIndex <= 52 ? 1 : 0, position: frameIndex >= 49 && frameIndex <= 52 ? 'absolute' : 'static',top: frameIndex >= 49 && frameIndex <= 52 ? '730.9%' : 'auto'}">Northbourne Avenue</h2>

       <h2 class="heading-02" :style="{opacity: frameIndex >= 53 && frameIndex <= 114 ? 1 : 0, position: frameIndex >= 53 && frameIndex <= 114 ? 'fixed' : 'static',top: frameIndex >= 53 && frameIndex <= 114 ? '48%' : 'auto'}">Northbourne Avenue</h2>

       <h2 class="heading-02" :style="{opacity: frameIndex >= 115 && frameIndex <= 120 ? 1 : 0, position: frameIndex >= 115 && frameIndex <= 120 ? 'absolute' : 'static',top: frameIndex >= 115 && frameIndex <= 120 ? '1440.9%' : 'auto'}">Northbourne Avenue</h2>

       <!-- heading 03 -->

       <h2 class="heading-03" :style="{opacity: frameIndex >=183 && frameIndex <= 187 ? 1 : 0, position: frameIndex >= 183 && frameIndex <= 187 ? 'absolute' : 'static',top: frameIndex >= 183 && frameIndex <= 187 ? '2343%' : 'auto'}">The newly built state of the art Yowani Clubhouse</h2>

       <h2 class="heading-03" :style="{opacity: frameIndex >= 188 && frameIndex <= 189 ? 1 : 0, position: frameIndex >= 188 && frameIndex <= 189 ? 'fixed' : 'static',top: frameIndex >= 188 && frameIndex <= 189 ? '48%' : 'auto'}">The newly built state of the art Yowani Clubhouse</h2>

       <h2 class="heading-03" :style="{opacity: frameIndex >=190 && frameIndex <= 192 ? 1 : 0, position: frameIndex >= 190 && frameIndex <= 192 ? 'absolute' : 'static',top: frameIndex >= 190 && frameIndex <= 192 ? '2358%' : 'auto'}">The newly built state of the art Yowani Clubhouse</h2>


       <!-- heading 04 -->

       <h2 class="heading-04" :style="{opacity: frameIndex >=193 && frameIndex <= 197 ? 1 : 0, position: frameIndex >= 193 && frameIndex <= 197 ? 'absolute' : 'static',top: frameIndex >= 193 && frameIndex <= 197 ? '2460%' : 'auto'}">Stage one of the Yowani redevelopment</h2>

       <h2 class="heading-04" :style="{opacity: frameIndex >= 198 && frameIndex <= 199 ? 1 : 0, position: frameIndex >= 198 && frameIndex <= 199 ? 'fixed' : 'static',top: frameIndex >= 198 && frameIndex <= 199 ? '48%' : 'auto'}">Stage one of the Yowani redevelopment</h2>

       <h2 class="heading-04" :style="{opacity: frameIndex >=200 && frameIndex <= 201 ? 1 : 0, position: frameIndex >= 200 && frameIndex <= 201 ? 'absolute' : 'static',top: frameIndex >= 200 && frameIndex <= 201 ? '2470%' : 'auto'}">Stage one of the Yowani redevelopment</h2>


       <!-- heading 05 -->

       <h2 class="heading-05" :style="{opacity: frameIndex >=203 && frameIndex <= 207 ? 1 : 0, position: frameIndex >= 203 && frameIndex <= 207 ? 'absolute' : 'static',top: frameIndex >= 203 && frameIndex <= 207 ? '2580%' : 'auto'}">Stage two of the Yowani redevelopment</h2>
        
       <h2 class="heading-05" :style="{opacity: frameIndex >= 208 && frameIndex <= 210 ? 1 : 0, position: frameIndex >= 208 && frameIndex <= 210 ? 'fixed' : 'static',top: frameIndex >= 208 && frameIndex <= 210 ? '48%' : 'auto'}">Stage two of the Yowani redevelopment</h2>

       <h2 class="heading-05" :style="{opacity: frameIndex >= 211 && frameIndex <= 214 ? 1 : 0, position: frameIndex >= 211 && frameIndex <= 214 ? 'absolute' : 'static',top: frameIndex >= 211 && frameIndex <= 214 ? '2598%' : 'auto'}">Stage two of the Yowani redevelopment</h2>
      
       <!-- heading 06 -->

       <h2 class="heading-06" :style="{opacity: frameIndex >=215 && frameIndex <= 219 ? 1 : 0, position: frameIndex >= 215 && frameIndex <= 219 ? 'absolute' : 'static',top: frameIndex >= 215 && frameIndex <= 219 ? '2740%' : 'auto'}">Final stage of the Yowani redevelopment</h2>

       <h2 class="heading-06" :style="{opacity: frameIndex >= 220 && frameIndex <= 240 ? 1 : 0, position: frameIndex >= 220 && frameIndex <= 240 ? 'fixed' : 'static',top: frameIndex >= 220 && frameIndex <= 240 ? '48%' : 'auto'}">Final stage of the Yowani redevelopment</h2>

       <h2 class="heading-06" :style="{opacity: frameIndex >=241 && frameIndex <= 244 ? 1 : 0, position: frameIndex >= 241 && frameIndex <= 244 ? 'absolute' : 'static',top: frameIndex >= 241 && frameIndex <= 244 ? '2969%' : 'auto'}">Final stage of the Yowani redevelopment</h2>


       <!-- heading 07 -->

       <h2 class="heading-07" :style="{opacity: frameIndex >=245 && frameIndex <= 249 ? 1 : 0, position: frameIndex >= 245 && frameIndex <= 249 ? 'absolute' : 'static',top: frameIndex >= 245 && frameIndex <= 249 ? '3095%' : 'auto'}">Proposed 68 mixed used apartments with basement parking and community facilities</h2>

       <h2 class="heading-07" :style="{opacity: frameIndex >= 250 && frameIndex <= 268 ? 1 : 0, position: frameIndex >= 250 && frameIndex <= 268 ? 'fixed' : 'static',top: frameIndex >= 250 && frameIndex <= 268 ? '48%' : 'auto'}">Proposed 68 mixed used apartments with basement parking and community facilities</h2>

       <h2 class="heading-07" :style="{opacity: frameIndex >=269 && frameIndex <= 274 ? 1 : 0, position: frameIndex >= 269 && frameIndex <= 274 ? 'absolute' : 'static',top: frameIndex >= 269 && frameIndex <= 274 ? '3307%' : 'auto'}">Proposed 68 mixed used apartments with basement parking and community facilities</h2>

       


       <!-- heading 08 -->

       <h2 class="heading-08" :style="{opacity: frameIndex >=275 && frameIndex <= 279 ? 1 : 0, position: frameIndex >= 275 && frameIndex <= 279 ? 'absolute' : 'static',top: frameIndex >= 275 && frameIndex <= 279 ? '3448%' : 'auto'}">Proposed 75 apartments with golf course views as well as 2 levels of basement parking</h2>

       <h2 class="heading-08" :style="{opacity: frameIndex >= 280 && frameIndex <= 290 ? 1 : 0, position: frameIndex >= 280 && frameIndex <= 290 ? 'fixed' : 'static',top: frameIndex >= 280 && frameIndex <= 290 ? '48%' : 'auto'}">Proposed 75 apartments with golf course views as well as 2 levels of basement parking</h2>

       <h2 class="heading-08" :style="{opacity: frameIndex >=291 && frameIndex <= 295 ? 1 : 0, position: frameIndex >= 291 && frameIndex <= 295 ? 'absolute' : 'static',top: frameIndex >= 291 && frameIndex <= 295 ? '3569%' : 'auto'}">Proposed 75 apartments with golf course views as well as 2 levels of basement parking</h2>

      


       <!-- heading 09 -->

       <h2 class="heading-09" :style="{opacity: frameIndex >=296 && frameIndex <= 299 ? 1 : 0, position: frameIndex >= 296 && frameIndex <= 299 ? 'absolute' : 'static',top: frameIndex >= 296 && frameIndex <= 299 ? '3700%' : 'auto'}">Proposed 94 dwellings (townhouses and apartments combined) great aspects and views of the golf course as well as 2 levels of basement parking</h2>


       <h2 class="heading-09" :style="{opacity: frameIndex >= 300 && frameIndex <= 310 ? 1 : 0, position: frameIndex >= 300 && frameIndex <= 310 ? 'fixed' : 'static',top: frameIndex >= 300 && frameIndex <= 310 ? '44%' : 'auto'}">Proposed 94 dwellings (townhouses and apartments combined) great aspects and views of the golf course as well as 2 levels of basement parking</h2>



       <h2 class="heading-09" :style="{opacity: frameIndex >=311 && frameIndex <= 315 ? 1 : 0, position: frameIndex >= 311 && frameIndex <= 315 ? 'absolute' : 'static',top: frameIndex >= 311 && frameIndex <= 315 ? '3802%' : 'auto'}">Proposed 94 dwellings (townhouses and apartments combined) great aspects and views of the golf course as well as 2 levels of basement parking</h2>


       

       <!-- heading 10 -->

       <h2 class="heading-10" :style="{opacity: frameIndex >=316 && frameIndex <= 319 ? 1 : 0, position: frameIndex >= 316 && frameIndex <= 319 ? 'absolute' : 'static',top: frameIndex >= 316 && frameIndex <= 319 ? '3940%' : 'auto'}">(Combined dwelling types, townhouses and apartments) more details soon to be released.</h2>

       <h2 class="heading-10" :style="{opacity: frameIndex >= 320 && frameIndex <= 328 ? 1 : 0, position: frameIndex >= 320 && frameIndex <= 328 ? 'fixed' : 'static',top: frameIndex >= 320 && frameIndex <= 328 ? '44%' : 'auto'}">(Combined dwelling types, townhouses and apartments) more details soon to be released.</h2>

       <h2 class="heading-10" :style="{opacity: frameIndex >=329 && frameIndex <= 334 ? 1 : 0, position: frameIndex >= 329 && frameIndex <= 334 ? 'absolute' : 'static',top: frameIndex >= 329 && frameIndex <= 334 ? '4034%' : 'auto'}">(Combined dwelling types, townhouses and apartments) more details soon to be released.</h2>

       

       <!-- heading 11 -->

       <h2 class="heading-11" :style="{opacity: frameIndex >=335 && frameIndex <= 340 ? 1 : 0, position: frameIndex >= 335 && frameIndex <= 340 ? 'absolute' : 'static',top: frameIndex >= 335 && frameIndex <= 340 ? '4190%' : 'auto'}">Proposed 5 storey community use, retail and commercial office building located opposite the light rail to the city.</h2>

       <h2 class="heading-11" :style="{opacity: frameIndex >= 341 && frameIndex <= 342 ? 1 : 0, position: frameIndex >= 341 && frameIndex <= 342 ? 'fixed' : 'static',top: frameIndex >= 341 && frameIndex <= 342 ? '44%' : 'auto'}">Proposed 5 storey community use, retail and commercial office building located opposite the light rail to the city.</h2>

       <h2 class="heading-11" :style="{opacity: frameIndex >=343 && frameIndex <= 346 ? 1 : 0, position: frameIndex >= 343 && frameIndex <= 346 ? 'absolute' : 'static',top: frameIndex >= 343 && frameIndex <= 346 ? '4198%' : 'auto'}">Proposed 5 storey community use, retail and commercial office building located opposite the light rail to the city.</h2>
          
     </div>



</div>
    
       <div class="body-text" :class="{ show: frameIndex >=350 && frameIndex <=361 }">
        <div class="container-fluid home-first-container">
           <div class="container">
              <div class="row mt-4">
                 <div class="col-md-6">
                    <div class="twobox-main-wrap">
                       <div class="img-box-wrap-vision">
                          <div class="box-title-wrap">
                             <p class="heading-h1" style="padding:0;marging:0">VISION</p>
                          </div>
                          <div class="row p-content-vision">
                             <p class="paraf-2-gray">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                             </p>
                          </div>
                          <div class="row p-content-vision">
                             <p class="paraf-2-gray">
                                containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                             </p>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <div class="twobox-main-wrap m-top-30-mobile">
                       <div class="img-box-wrap-vision">
                          <div class="box-title-wrap">
                             <p class="heading-h1" style="padding:0;marging:0">GREEN STAR COMMUNITIES</p>
                          </div>
                          <div class="row p-content-vision">
                             <p class="paraf-2-gray">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                             </p>
                          </div>
                          <div class="row p-content-vision">
                             <p class="paraf-2-gray">
                                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
                             </p>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div class="row mt-4">
                 <div class="col-md-6">
                    <div class="twobox-main-wrap">
                       <div class="box-future-img-wrap img-box-wrap residential-box-wrap">
                          <div class="box-title-wrap">
                             <h1>RESIDENTIAL</h1>
                          </div>
                          <div class="box-bottom-wrap">
                             <div class="box-bottom-para-wrap">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                             </div>
                             <div class="box-bottom-btn-wrap">
                                <a href="#" class="btn btn-light">Enquire Now</a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <div class="twobox-main-wrap m-top-30-mobile">
                       <div class="box-current-img-wrap img-box-wrap">
                          <div class="box-title-wrap">
                             <h1>COMMERCIAL</h1>
                          </div>
                          <div class="box-bottom-wrap">
                             <div class="box-bottom-para-wrap">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                             </div>
                             <div class="box-bottom-btn-wrap">
                                <a href="#" class="btn btn-light">Enquire Now</a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div class="container-fluid mission-page">
           <div class="row justify-content-center mission-section-05-padding">
              <div class="col-md-12">
                 <div class="row mission-section-05">
                    <div class="col-md-8">
                       <div class="contect-nission-box">
                          <p class="heading-h1 color-dulwhite line-height">
                             CAREERS
                          </p>
                          <p class="paraf-2-gray">
                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                          </p>
                          <div class="box-bottom-btn-wrap">
                             <a href="#" class="btn btn-light">Join Now</a>
                          </div>
                       </div>
                    </div>
                    <div class="col"></div>
                 </div>
              </div>
           </div>
        </div>
        <div class="container-fluid">
           <div class="row justify-content-center inner-more-projects">
              <div class=" foter-container">
                 <div class="row">
                    <p class="paraf-2-gray">
                       More Projects
                    </p>
                 </div>
                 <div class="row">
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="#">
                             <p class="heading-h2-foot">RISE</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Bruce</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/sparks">
                             <p class="heading-h2-foot">SPARKS</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Aranda</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2 ">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/wish">
                             <p class="heading-h2-foot">WISH</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Bruce</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/luxus">
                             <p class="heading-h2-foot">LUXUS</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Dickson</p>
                       </div>
                    </div>
                 </div>
                 <div class="row mt-5">
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/verve">
                             <p class="heading-h2-foot">VERVE</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Newcastle</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/bindaga">
                             <p class="heading-h2-foot">BINDAGA</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Aranda</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2 ">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/solong">
                             <p class="heading-h2-foot">SOLONG</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Lawson</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/nestIII">
                             <p class="heading-h2-foot">NEST III</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Lawson</p>
                       </div>
                    </div>
                 </div>
                 <div class="row mt-5">
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/nestII">
                             <p class="heading-h2-foot">NEST II</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Lawson</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/nestI">
                             <p class="heading-h2-foot">NEST I</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Lawson</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2 ">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/amaroo">
                             <p class="heading-h2-foot">AMAROO</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Amaroo</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/pioneer">
                             <p class="heading-h2-foot">PIONEER</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Amaroo</p>
                       </div>
                    </div>
                 </div>
                 <div class="row mt-5">
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/hillcrest">
                             <p class="heading-h2-foot">HILLCREST</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Weston</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/ives">
                             <p class="heading-h2-foot">IVES</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Bruce</p>
                       </div>
                    </div>
                    <div class="foter-slash"></div>
                    <div class="col-12 col-md-4 col-lg-2">
                       <div class="row">
                          <a href="https://www.tpdynamics.com.au/Yowani">
                             <p class="heading-h2-foot active">YOWANI</p>
                          </a>
                       </div>
                       <div class="row">
                          <p class="paraf-4 ">Bush Capital</p>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <footer class="custom-padding-left3 custom-padding-right3">
          <div class="container-fluid">
    
            <div class="row">
              <div class="col-md-12">
                <div class="footer-logo-wrap">
                  <a href="/"><img src="/tp-logo-white.png" alt="Dynamics-image" class="footer-image-one"></a>
                </div>
              </div>
              <div class="col-md-12 pt-4">
                <div class="footer-bootom-last-wrap">
                  <ul class="pl-0">
                    <li ><p class="mb-0">Copyright 2022 TP Dynamics, Level 3 56/11, Thynne Street, Bruce ACT 2617 | ABN 15 820 948 786 | All
                      rights reserved.</p></li>
    
                    <li class="px-2"><a href="tel: 02 6154 8900" target="_blank">Phone: 02 6154 8900 </a></li>
                    <li>Email:<a href="mailto:admin@tpdynamics.com.au" target="_blank" class="footer-email-anchor">
                      admin@tpdynamics.com.au</a></li>
                    <li class="px-2"><p class="mb-0">Created by <a href="https://linkingintegrating.com/" target="_blank">Linking Integrating</a></p></li>
                  </ul>
                </div>
    
              </div>
    
            </div>
          </div>
        </footer>
     </div>
    
    
      
      </div>
    
    
    </template>

    <script>
    
    export default {
      name: 'HelloWorld',
  
      mounted() {

        setTimeout(() => {
              this.loading= false;
         }, 20000);
        
        
        const canvas = this.$refs.canvas;
        const context = canvas.getContext("2d");
  
        const frameCount = 360;
  
        const preloadImages = () => {
          const promises = [];
        
          for (let i = 1; i < frameCount; i++) {
            const img = new Image();
            const promise = new Promise((resolve, reject) => {
              img.onload = () => {
                resolve();
              };

              img.onerror = () => {  
                    reject();
              };
            });
            img.src = this.currentFrame(i);
            promises.push(promise);
          }
          return Promise.all(promises);
        };
  
        const img = new Image();
        img.src = this.currentFrame(1);
  
       

        if(this.isMobile()) {

            canvas.width=1080;
             canvas.height=1920;

             this.mobile=true;
        }else {
            canvas.width = 1900;
            canvas.height = 1000;

            this.mobile=false;
        }
  
        img.onload = () => {
          context.drawImage(img, 0, 0);
        };
  
        preloadImages().then(() => {
          if (this.isMobile()) {
            window.addEventListener("scroll", this.handleScroll);
          } else {
            window.addEventListener("scroll", this.handleScroll);
          }
        }).catch(error => {
          console.error("Error loading images:", error);
        });
      },
  
      beforeUnmount() {
        if (this.isMobile()) {
          window.removeEventListener("scroll", this.handleScroll);
        } else {
            
          window.removeEventListener("scroll", this.handleScroll);
        }
      },
  
      methods: {
        currentFrame(index) {
          const assetPath = this.isMobile() ? 'Banner-Video-mobile-Webp' : 'Banner-Video-Webp';
          return `/${assetPath}/${index + 1}.jpg`;
        },
  
        handleScroll() {
          const scrollTop = this.getScrollTop();
          const maxScrollTop = this.getMaxScrollTop();
          const scrollFraction = scrollTop / maxScrollTop;
  
          const frameIndex = Math.min(
            this.frameCount - 1,
            Math.ceil(scrollFraction * this.frameCount)
          );
  
          this.updateVisibility(frameIndex);
          this.updateImage(frameIndex + 1);
        },
  
        updateVisibility(frameIndex) {
          this.frameIndex = frameIndex;
        },
  
        updateImage(index) {
          const img = new Image();
          img.src = this.currentFrame(index);
          const context = this.$refs.canvas.getContext("2d");
          img.onload = () => {
            context.drawImage(img, 0, 0);
          };
        },
  
        isMobile() {
          return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 768 );
        },
  
        getScrollTop() {
          return this.isMobile() ? document.documentElement.scrollTop || document.body.scrollTop : window.pageYOffset;
        },
  
        getMaxScrollTop() {
          return this.isMobile() ? document.documentElement.scrollHeight - window.innerHeight : document.documentElement.scrollHeight - document.documentElement.clientHeight;
        },
      },
  
      data() {
        return {
          frameCount: 360,
          frameIndex: 0,
          loading: true,
          mobile:false
        };
      },
    };
  </script>
  
    


    
    
    <style>

    img.scroll-down-image {
        position: fixed;
        top: 90%;
        width: 4%;
        left: 53.5%;
        transform: translate(-50%, -50%);
    }

    #smooth-body{
        width: 100%;
        height: 100vh;
      

    }
    
        .body-text::-webkit-scrollbar {
             width: 0px;
             background: transparent;
            }
        
        body {
        height: 4500vh;
        
        }
        .text {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        }
        .body-text{
        display: none;
        }
        .body-text {
        background-image: url(/public/tp-background.jpg);
        position: relative;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        }
        #preloader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(/public/tp-background.jpg);
        z-index: 99;
        }
        #status {
        width: 100%;
        height: 100%;
        background-image: url(/public/50X50.gif);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        }
        canvas {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: -1;
        }
        
        h2.heading-01.visible{
        opacity: 1;
        position: absolute;
        top: 177.3%;
        width: 100%;
        text-align: center;
        }
        h2.heading-02.visible{
        opacity: 1;
        position: absolute;
        top: 1002.9%;
        width: 100%;
        text-align: center;
        }
        h2.heading-03.visible{
        opacity: 1;
        position: absolute;
        top: 2284%;
        width: 100%;
        text-align: center;
        }
        h2.heading-04.visible{
        opacity: 1;
        position: absolute;
        top: 2437%;
        width: 100%;
        text-align: center;
        }
        h2.heading-05.visible{
        opacity: 1;
        position: absolute;
        top: 2553%;
        width: 100%;
        text-align: center;
        }
        h2.heading-06.visible{
        opacity: 1;
        position: absolute;
        top: 2711%;
        width: 100%;
        text-align: center;
        }
        h2.heading-07.visible{
        opacity: 1;
        position: absolute;
        top: 3076%;
        width: 100%;
        text-align: center;
        }
        h2.heading-08.visible{
        opacity: 1;
        position: absolute;
        top: 3436%;
        width: 100%;
        text-align: center;
        }
       h2.heading-09.visible{
        opacity: 1;
        position: absolute;
        top: 3689%;
        width: 100%;
        text-align: center;
        }
        
       h2.heading-10.visible{
        opacity: 1;
        position: absolute;
        top: 3931%;
        width: 100%;
        text-align: center;
        }
        
        h2.heading-11.visible{
        opacity: 1;
        position: absolute;
        top: 4183%;
        width: 100%;
        text-align: center;
        }
        
    .heading-01,
    .heading-02,
    .heading-03,
    .heading-04,
    .heading-05,
    .heading-06,
    .heading-07,
    .heading-08,
    .heading-09,
    .heading-10,
    .heading-11 {
      opacity: 0;
    }
        
        
        .text h2{
        color: #fff !important;
        width: 40% !important;
        margin: 0px auto !important;
        text-align: center !important;
        letter-spacing: 0 !important;
        text-shadow: 2px 2px 8px #000000e6 !important;
        font-size: 30px !important;
        font-weight: 600;
        line-height: 44px !important;
        font-family: Raleway,sans-serif!important;
        }
        .img-box-wrap-vision {
        background-color: #20221d;
        position: relative;
        min-height: 460px;
        background-position: center center;
        }
        .p-content-vision {
        padding-top: 0px;
        padding-left: 46px;
        height: auto;
        max-width: 502px;
        }
        .body-text{
         display: none;
        }
    
        .body-text.show {
          display: block;
          position: fixed;
          width: 100%;
          bottom: 0%;
          top: 5%;
          overflow-y: scroll;
          overflow-x: scroll;
          min-height: 100%;
          animation: fadeInUp 1s !important;
      }

      
    
    
    
      @import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700&display=swap);
    body,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        /* background: #141414 !important; */
        font-family: "Raleway", sans-serif !important;
    }
    
    .color-gold {
        color: #a0916e !important;
    }
    
    .color-gray {
        color: #BEBEBE !important;
    }ER
    
    .color-dulwhite {
        color: #E5E5E5 !important;
    }
    
    .history-inv-p-0 {
        padding: 0px !important;
    }
    
    
    
    .navbar.navbar-dark {
        background-color: #14141400 !important;
    }
    
    .pos-f-t {
      position: fixed;
      top: 0;
      z-index: 60;
      background-color: #20221d;
      width: 100%;
    }
    
    footer {
        background: #20221d;
        padding-top: 60px;
        padding-bottom: 40px;
        margin-top: 30px;
    }
    
    .footer-quick-links-wrap ul li a:hover {
        color: #a0916e;
        text-decoration: none !important;
    }
    
    
    /* Font Changes */
    
    
    /*sunnda*/
    
    .heading-h1,
    .home-completed-project-title-wrap h1 {
        font-family: "Raleway", sans-serif !important;
        font-size: 50px;
        letter-spacing: 3.96px;
        font-weight: 300;
        color: #a0916e;
        text-transform: uppercase;
        opacity: 1;
        line-height: 70px !important;
    }
    
    .heading-h1-wht,
    .box-title-wrap h1 {
        font-family: "Raleway", sans-serif !important;
        font-size: 50px;
        font-weight: 300;
        letter-spacing: 3.96px;
        color: #e5e5e5;
        text-transform: uppercase;
        opacity: 1;
    }
    
    .heading-h2,
    .heading-h2 a {
        font-family: "Raleway", sans-serif !important;
        font-size: 28px;
        letter-spacing: 0px;
        color: #a0916e;
        opacity: 1;
    }
    
    .heading-h2-foot {
        font-family: "Raleway", sans-serif !important;
        font-size: 38px;
        letter-spacing: 0px;
        line-height: 30px;
        color: #a0916e;
        opacity: 1;
    }
    
    .our-values-list-wrap ol h5 {
        font-weight: 400;
    }
    
    .our-values-list-wrap ol p {
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    
    .heading-h2-gray,
    .heading-h2-gray a,
    .our-values-list-wrap ol h5 {
        font-family: "Raleway", sans-serif !important;
        font-size: 28px;
        letter-spacing: 0px;
        color: #bebebe;
        opacity: 1;
    }
    
    
    
    
    
    
    .about-progress-wrap-text .pt-4 {
        padding-top: 3.5rem!important;
    }
    
    .about-progress-wrap-text .heading-h1 {
        line-height: 30px !important;
    }
    
    .row.main-content-box {
        background-color: #20221d;
        margin-bottom: 30px;
        padding-bottom: 0px;
    }
    
    .row.main-content-box.family-con-right-box {
        margin-bottom: 0px !important;
    }
    
    .icon-description-wrap p,
    .about-drag-text-h1,
    .slider-text-h3 {
        font-family: "Raleway", sans-serif !important;
        font-size: 20px;
        letter-spacing: 0px;
        color: #bebebe;
        line-height: 34px;
        opacity: 1;
    }
    
    .heading-h2-gray.active a,
    .heading-h2-gray a:hover {
        color: #a0916e !important;
        text-decoration: none !important;
    }
    
    .paraf-1 {
        font-family: "Raleway", sans-serif !important;
        font-size: 18px;
        letter-spacing: 0.36px;
        color: #a0916e;
        text-transform: uppercase;
    }
    
    .paraf-2-gray,
    .first-bar::after,
    .box-bottom-para-wrap p,
    .home-completed-project-title-wrap p,
    .our-values-list-wrap ol p {
        font-family: "Raleway", sans-serif !important;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.18px;
        line-height: 27px;
        color: #bebebe;
    }
    
    .paraf-3,
    footer h3 {
        font-family: "Raleway", sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.4px;
        color: #a0916e;
        text-transform: uppercase;
        opacity: 1;
    }
    
    .paraf-3-wht,
    .progressBarContainer .item h3 {
        font-family: "Raleway", sans-serif !important;
        font-size: 14px;
        letter-spacing: 1.4px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
    }
    
    .paraf-4,
    .progressBarContainer .item p,
    .home-top-nav-list ul li a {
        font-family: "Raleway", sans-serif !important;
        font-size: 14px;
        letter-spacing: 0px;
        color: #909090;
        opacity: 1;
    }
    
    .paraf-5 {
        font-family: "Raleway", sans-serif !important;
        font-size: 12px;
        letter-spacing: 0px;
        color: #a0916e;
        opacity: 1;
    }
    
    .paraf-5-gray,
    footer p,
    footer ul li,
    footer ul li a,
    footer .links,
    .footer-createdby-wrap a {
        font-family: "Raleway", sans-serif !important;
        font-size: 12px;
        letter-spacing: 0px;
        color: #909090;
        opacity: 1;
    }
    
    .home-team-full-wrap .box-title-wrap h1 {
        font-size: 48px;
    }
    
    
    /* Font Changes */
    
    
    /*Btn changes*/
    
    .gold-btn:hover,
    .box-bottom-btn-wrap a:hover,
    .box-bottom-btn-wrap button:hover {
        color: #ffffff !important;
        background: #B1924F;
    }
    
    .team-bottem-btn a:hover,
    .trans-btn:hover {
        color: #ffffff !important;
        background: #B1924F;
    }
    
    .box-sml-btn a {
        background: #a0916e !important;
        color: #ffffff !important;
    }
    
    .gold-btn,
    .box-bottom-btn-wrap a,
    .box-bottom-btn-wrap button {
        background: #A0916E;
        border-color: #A0916E;
        border-radius: 0px;
        padding: 12px 20px 12px 20px;
        letter-spacing: 1.4px;
        font-family: "Raleway", sans-serif !important;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        text-transform: uppercase;
        box-shadow: none !important;
        outline: 0px !important;
    }
    
    .trans-btn:hover,
    .team-bottem-btn a:hover,
    .trans-btn:focus,
    .team-bottem-btn a:focus {
        color: #ffffff !important;
    }
    
    .trans-btn,
    .team-bottem-btn a {
        border-color: #a0916e;
        border-radius: 0px;
        padding: 12px 20px 12px 20px;
        letter-spacing: 1.4px;
        font-family: "Raleway", sans-serif !important;
        font-weight: 600;
        font-size: 12px;
        color: #a0916e !important;
        text-transform: uppercase;
        box-shadow: none !important;
        outline: 0px !important;
    }
    
    
    /*Btn changes*/
    
    
    /* text field changes */
    
    .txt-active {
        max-width: 524px;
        height: 56px;
        background: #e5e5e5 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        opacity: 1;
    }
    
    
    /*Short code*/
    
    .display-none,
    .d-none {
        display: none !important;
    }
    
    ul li {
        list-style: none !important;
    }
    
    .custom-nav-button .navbar-toggler .icon-bar {
        background-color: #fff;
    }
    
    .custom-nav-button .navbar-toggler .icon-bar {
        display: block;
        width: 22px;
        margin-bottom: 5px;
        height: 2px;
        position: relative;
        border-radius: 1px;
    }
    
    .first-bar::after {
        content: "Menu";
        position: absolute;
        top: -7px;
        margin-left: 16px;
    }
    
    .custom-padding-left3 {
        padding-left: 3vw;
    }
    
    .custom-padding-right3 {
        padding-right: 3vw;
    }
    
    .custom-padding-right5 {
        padding-right: 5vw;
    }
    
    .m-top-30 {
        margin-top: 30px;
    }
    
    .m-bottom-30 {
        margin-bottom: 30px;
    }
    
    .p-top {
        padding-top: 30px;
    }
    
    .p-bottom {
        padding-bottom: 30px;
    }
    
    
    /*Short code*/
    
    
    /* .footer-logo-wrap img,
    .navbar-brand img {
        width: 70%;
    } */
    
    .navbar-dark .navbar-toggler,
    .navbar-toggler:focus {
        border-color: rgb(255 255 255 / 0%) !important;
        outline: none !important;
    }
    
    .top-nav-list-wrap .col-md-4 {
        display: flex;
        /* align-items: center; */
        justify-content: center;
    }
    
    .bottom-heading-nav {
        margin-left: -74px;
    }
    
    .top-nav-list-wrap {
        padding-bottom: 40px;
    }
    
    .slider-custom-caption {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
        top: 50%;
        margin-top: -113px;
    }
    
    
    .box-bottom-wrap {
        position: absolute;
        bottom: 30px;
        left: 30px;
    }
    
    .box-title-wrap {
        padding-top: 20px;
        padding-left: 28px;
    }
    
    .home-first-container {
        padding-top: 30px;
    }
    
    
    .carousel-indicators li {
        width: 8px !important;
        height: 8px !important;
        border-radius: 50% !important;
    }
    
    .carousel-indicators .active {
        background-color: #a0916e !important;
    }
    
    .news-list-wrap ul li {
        position: relative;
        line-height: 45px;
    }
    
    .news-list-wrap ul li::before {
        /* content: "f105"; */
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
        font-size: 15px;
        top: -1px;
        left: -10px;
    }
    
    .footer-project-wrap ul li a:hover,
    .links:hover,
    .footer-info-wrap ul li a:hover {
        color: #a0916e;
        text-decoration: none;
    }
    
    .footer-email-anchor,
    .about-descript a.links,
    .box-team-img-wrap .box-title-wrap h1,
    .box-about-img-wrap .box-title-wrap h1,
    .box-news-img-wrap .box-title-wrap h1,
    .box-history-img-wrap .box-title-wrap h1 {
        color: #a0916e;
    }
    
    .footer-project-wrap ul li,
    .footer-quick-links-wrap ul li,
    .footer-info-wrap ul li {
        line-height: 25px;
    }
    
    
    /*Home slider*/
    
    .sliderContainer {
        position: relative;
    }
    
    .slider {
        width: 100%;
        /* margin: 30px 50px 50px; */
    }
    
    .line-height {
        line-height: 58px !important;
    }
    
    .pt-100 {
        padding-top: 100px !important;
    }
    
    .pb-100 {
        padding-bottom: 100px !important;
    }
    
    h1.banner-text-content.mb-0 {
        font-family: "Raleway", sans-serif !important;
        font-size: 66px;
        font-weight: 300;
        letter-spacing: 3.96px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
    }
    
    .text-center {
        text-align: center !important;
    }
    
    p.heading-h1.pro-info-header {
        font-family: "Raleway", sans-serif !important;
        font-size: 66px;
        letter-spacing: 3.96px;
        font-weight: 300;
        color: #a0916e;
        text-transform: uppercase;
        opacity: 1;
        line-height: 72px;
        margin-bottom: 35px;
    }
    
    a:hover {
        text-decoration: none !important;
    }
    
    .slick-slide {
        opacity: 0.6;
    }
    
    .slick-slide.slick-current.slick-active {
        opacity: 0.9;
    }
    
    .progressBarContainer .item h3 {
        font-size: 13px;
    }
    
    .progressBarContainer .item p {
        font-size: 12px;
    }
    
    .slick-slide {
        margin: 0px 15px;
        height: 645px !important;
        background-size: cover;
        background-position: center center;
        color: white;
        padding: 80px 0;
        /* padding: 80px 0 120px; */
        font-size: 30px;
        font-family: "Arial", "Helvetica";
        text-align: center;
    }
    
    
    .d-table.w100.h100 {
        width: 100%;
        height: 100%;
        text-align: center;
    }
    
    .d-table {
        display: table !important;
    }
    
    .slick-prev:before,
    .slick-next:before {
        color: black;
    }
    
    .d-table.w100.h100 {
        width: 100%;
        height: 100%;
        text-align: center;
    }
    
    .align-middle {
        vertical-align: middle !important;
        margin: auto;
    }
    
    .d-table-cell {
        display: table-cell !important;
    }
    
    .slick-dots {
        bottom: -30px;
    }
    
    .progressBarContainer {
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 40px;
        /* bottom: 20px;
        width: 300px;
        left: 150px; */
        /* width: 300px; */
        /* left: 150px; */
        /* position: absolute;
        bottom: 20px;
        right: 15%;
        bottom: 20px;
        left: 15%;
        z-index: 10;
        padding-top: 20px; */
    }
    
    .progressBarContainer div {
        display: block;
        width: 17%;
        padding: 0;
        cursor: pointer;
        /* margin-right: 5%; */
        float: left;
        color: white;
    }
    
    .progressBarContainer div:last-child {
        margin-right: 0;
    }
    
    .progressBarContainer div span.progressBar {
        width: 100%;
        height: 4px;
        background-color: rgba(255, 255, 255, 0.4);
        display: block;
        position: relative;
    }
    
    .progressBarContainer div span.progressBar .inProgress {
        background-color: #a0916e;
        width: 0%;
        height: 4px;
    }
    
    .progressBarContainer div span.progressBar::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: -3px;
        background: #a0916e;
        border-radius: 50%;
    }
    
    
    /*sunnada  70% => 80%*/
    
    .box-bottom-para-wrap {
        width: 80%;
    }
    
    .home-middle-slider-wrap {
        background: #20221d;
        padding-top: 35px;
    }
    
    .home-completed-project-title-wrap {
        padding-top: 30px;
        padding-bottom: 25px;
    }
    
    
    /* Team Info CSS */
    
    .img-person {
        margin-left: 2% !important;
        min-height: 761px !important;
        width: 100% !important;
        height: auto;
        /* background-image: url("/TeamInfo/team-tony-2.jpg"); */
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
    }
    
    
    .fam-con-img,
    .fam-con-img:hover,
    .col-12.col-md-5.img-person,
    .col-12.col-md-5.img-person:hover,
    .team-bottem-btn a:hover,
    .trans-btn:hover,
    .team-bottem-btn a,
    .trans-btn {
        transition: all 0.6s ease !important;
    }
    
    
    /* projectsAll CSS */
    
    .img-box {
        width: 100%;
        min-height: 648px !important;
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
    }
    
    .box-content {
        position: absolute !important;
        top: 38%;
    }
    
    .box-content p {
        color: #e5e5e5;
    }
    
    .project-btn a {
        width: 190px;
        height: 56px;
        font-size: 16px;
        padding-top: 15px;
    }
    
    
    /* project-commercial CSS */
    
    .main-content-box {
        padding-bottom: 36px;
    }
    
    p.heading-h2-foot.active {
        color: #ead5a3;
    }
    
    .content-box {
        height: 530px;
        max-width: 760px !important;
        padding-left: 60px !important;
        padding-top: 87px;
    }
    
    .black {
        background-color: #20221d;
    }
    
    .con {
        max-width: 1520px !important;
    }
    
    .justify-content-center .heading-h2-foot {
        font-family: "Raleway", sans-serif !important;
        font-size: 30px;
        letter-spacing: 0px;
        line-height: 30px;
        color: #a0916e;
        text-transform: uppercase;
        opacity: 1;
    }
    
    .box-sml-btn a {
        width: 100px;
        height: 26px;
        padding: 3px 0 0 0;
    }
    
    
    /* .p-content {
        height: 171px;
        max-width: 614px;
    } */
    
    .p-content {
        height: auto;
        max-width: 606px;
    }
    
    .box-lg-btn a {
        width: 114px;
        height: 48px;
        padding: 14px 37px 15px 37px;
        margin-top: 20px;
    }
    
    .pro-com-box-img {
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
    }
    
    .content-right {
        float: right;
    }
    
    .content-right p {
        text-align: right;
    }
    
    
    /* team CSS */
    
    .ban-header {
        margin-top: 100px;
    }
    
    .ban-para {
        max-width: 1212px;
        max-height: 115px;
        margin-top: 20px;
        margin-bottom: 100px;
        text-align: center;
    }
    
    .header-line {
        height: 8px;
        background-color: #a0916e;
    }
    
    .team-box-img {
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
    }
    
    .team-box-img:hover a {
        display: block;
    }
    
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        /* height:100px; */
        width: 3%;
        margin: 0 15px 0 5px;
        /* margin-top: -22px; */
        color: white !important;
        /* background-color: #b0b0b0; */
        font-weight: lighter;
        font-size: 48px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        display: none;
    }
    
    .next {
        position: absolute;
        right: 0px;
        border-radius: 3px 0 0 3px;
    }
    
    .prev {
        position: absolute;
        left: 0px;
        border-radius: 3px 0 0 3px;
    }
    
    
    /* On hover, add a black background color with a little bit see-through */
    
    .prev:hover,
    .next:hover {
        /* background-color: #b0b0b0; */
        color: #3d3d3d;
    }
    
    .content-sml-box {
        height: 250px;
        max-width: 760px !important;
        padding-left: 60px !important;
        padding-top: 87px;
    }
    
    .family-con {
        max-width: 1850px;
        background-color: #20221d;
        width: 100%;
        z-index: 0;
    }
    
    .family-con div {
        margin: 0;
    }
    
    
    /* .family-con-box {
        padding: 70px 0 0 65px !important;
        z-index: 80;
    } */
    
    
    /* .family-con-right-box {
        padding: 60px 10px 60px 60px !important;
        border-left: 1px solid #7e868d;
    } */
    
    .right-box-header {
        max-width: 480px;
    }
    
    .right-box-para {
        max-width: 700px;
    }
    
    .right-conbox {
        margin-top: 60px !important;
    }
    
    .fam-con-img {
        width: 100%;
        height: 390px !important;
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
        background-size: 100%;
        transition: background 0.8s;
        -webkit-transition: background 0.8s;
    }
    
    .right-conbox:hover .row>.right-conbox-btn a,
    .right-conbox:hover .team-bottem-btn a,
    .right-conbox:hover .team-bottem-btn a:hover {
        background-color: #a0916e;
        border-color: #a0916e;
        color: #ffffff !important;
    }
    
    .right-conbox:hover .right-conbox-btn a {
        background-color: #a0916e;
        border-color: #a0916e;
        color: #ffffff !important;
    }
    
    
    /* .right-conbox:hover .right-conbox a {
        background: #bebebe !important;
    } */
    
    .right-conbox::before {
        width: 15px;
    }
    
    .right-conbox-dis {
        margin-top: 20px !important;
    }
    
    .right-conbox-para {
        margin-top: -15px !important;
    }
    
    .right-conbox-btn {
        width: 130px;
        height: 48px;
    }
    
    
    /* Contact CSS */
    
    .con-content {
        margin: 36px 150px 0 150px;
        width: 100%;
    }
    
    .con-con-para {
        max-width: 1053px;
    }
    
    .con-left {
        max-width: 552px !important;
    }
    
    .con-right {
        max-width: 1124px !important;
    }
    
    .txt-con {
        margin: 18px 0 20px 20px;
    }
    
    .txt-container {
        margin-bottom: 20px;
    }
    
    .rich-txt-container {
        max-width: 1068px !important;
        height: 191px;
        margin-bottom: 20px;
    }
    
    .rb-styl {
        width: 32px;
        height: 32px;
        color: #4e4e4e;
    }
    
    .txt-rb {
        margin: 3px 0 20px 10px;
    }
    
    .contact-submit-btn a {
        max-width: 1069px;
        width: 100%;
        height: 48px;
        padding: 14px 37px 15px 37px;
        margin-bottom: 100px;
    }
    
    
    /* projects-info CSS */
    
    .pro-info-main-container {
        padding: 0 200px 0 200px !important;
    }
    
    .pro-info-left-con {
        max-width: 759px !important;
        width: 100%;
    }
    
    .pro-info-right-con {
        padding: 35px 0 0 90px !important;
        max-width: 759px !important;
        width: 100%;
    }
    
    
    /*About us page style start*/
    
    .our-guiding-wrap-row {
        background: #20221d;
    }
    
    
    /* .heading-col-wrap p {
        line-height: 72px;
    } */
    
    .our-values-list-wrap ol {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    
    .our-values-list-wrap ol {
        position: relative;
        list-style: none;
        counter-reset: my-awesome-counter;
    }
    
    ol li {
        counter-increment: my-awesome-counter;
    }
    
    .our-values-list-wrap ol {
        padding: 2px;
    }
    
    .content-box.top-contentbox-wrap p.heading-h1.mb-0 {
        line-height: 55px;
    }
    
    .resident-project p.heading-h1 {
        line-height: 90px;
    }
    
    .our-values-list-wrap ol li::before {
        position: absolute;
        content: counter(my-awesome-counter) ". ";
        margin-left: -36px;
        font-size: 28px;
        margin-top: -7px;
        font-weight: 400 !important;
        color: #A0916E !important;
    }
    
    .container.about-progress-wrap-text {
        margin-bottom: 50px;
    }
    
    .our-values-list-wrap ol li {
        padding-left: 30px;
    }
    
    .principles-icons-wrap {
        padding-top: 40px;
    }
    
    .our-guiding-wrap-row {
        padding-top: 50px;
        padding-bottom: 50px !important;
        margin-bottom: 30px !important;
    }
    
    .about-progress-wrap {
        position: relative;
        width: 100%;
        height: 400px;
    }
    
    .panorama-viewport {
        width: 100% !important;
    }
    
    .top-contentbox-wrap {
        height: auto !important;
        padding-top: 80px !important;
        padding-bottom: 30px !important;
    }
    
    .panorama-control {
        display: none !important;
    }
    
    
    /*About us page style end*/
    
    .pro-info-img img {
        display: block;
        max-width: 100%;
        width: 100%;
        background-position: top center;
        margin-bottom: 3px !important;
    }
    
    
    /*
    .pro-info-img1 {
        width: 100%;
        height: 1014px !important;
        background-repeat: no-repeat;
        position: relative;
        background-position: top center;
        background-size: 100% 100%;
    }
     .pro-info-img2 {
        width: 100%;
        height: 561px !important;
        background-repeat: no-repeat;
        position: relative;
        background-position: top center;
        background-size: 100% 100%;
    }
    .pro-info-img3 {
        width: 100%;
        height: 397px !important;
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
        background-size: 100% 100%;
    }  */
    
    .pro-info-link-box {
        width: 319px !important;
        /* height: 30px !important;
        border-bottom: 1px solid #BEBEBE; */
        height: 35px !important;
        border-bottom: 1px solid #707070;
        cursor: pointer;
    }
    
    .pro-info-header {
        margin-top: -10px;
    }
    
    .pro-info-adres {
        margin-top: -30px;
        margin-bottom: 30px;
    }
    
    .pro-info-title {
        margin-top: 40px;
    }
    
    .pro-info-para {
        margin-bottom: 40px;
    }
    
    .foter-container {
        max-width: 1850px !important;
        width: 100%;
        background-color: #20221d;
        margin-top: 95px;
        padding: 77px 155px 77px 155px;
    }
    
    .foter-slash {
        width: 2px;
        height: 58px;
        /* background-color: #646464; */
        background-color: #646464a6;
        display: block;
        margin-right: 80px;
    }
    
    
    /* news CSS */
    
    .news-con {
        max-width: 1720px !important;
        background-color: #20221d;
        width: 100% !important;
        flex: 0 0 100% !important;
        padding: 0 0 100px 0;
    }
    
    .white-header {
        color: #e5e5e5;
        margin-top: -8%;
    }
    
    .header-content-box {
        padding: 100px 100px 100px 100px;
    }
    
    .col-container {
        max-width: 516px !important;
        width: 100%;
        margin: 0 0 0 36px;
    }
    
    .contenent-box {
        width: 100%;
        height: 184px;
        padding-top: 10px;
    }
    
    .news-new-content-box .contenent-box {
        width: 100%;
        min-height: 120px !important;
        height: auto !important;
        padding-top: 10px;
    }
    
    .lg-img-box {
        /* max-width: 516px !important; */
        width: 100%;
        /* height: 514px !important; */
        height: auto;
    }
    
    .md-img-box {
        /* max-width: 516px !important; */
        width: 100%;
        /* height: 364px !important; */
        height: auto;
    }
    
    .sml-img-box {
        /* max-width: 516px !important; */
        width: 100%;
        /* height: 236px !important; */
        height: auto;
    }
    
    .col-box {
        padding: 0 14px 0 14px;
    }
    
    .news-btn a {
        width: 140px;
        height: 48px;
        padding: 14px 20px 15px 20px;
        margin-top: 20px;
    }
    
    .show-more {
        display: none;
    }
    
    .news-inner-con {
        max-width: 1518px;
        width: 100%;
        background-color: #20221d;
        padding: 36px 153px 0 153px;
    }
    
    .news-naw {
        margin: 10px 20px;
    }
    
    .news-inner-img {
        max-width: 1212px !important;
        width: 100%;
        height: auto;
    }
    
    
    /* link style */
    
    
    /* mouse over link */
    
    a:hover {
        color: #b1924f !important;
        text-decoration: none;
    }
    
    
    /* popup */
    
    
    /* @media (min-width: 768px) { */
    
    .modal-dialog {
        text-align: left;
        top: 20% !important;
        vertical-align: middle;
    }
    
    
    /* } */
    
    .modal-dialog {
        max-width: 1050px !important;
        width: 100%;
    }
    
    .modal-content {
        padding: 40px;
        padding-bottom: 40px;
        overflow: hidden;
        background-color: #1b1b1b !important;
    }
    
    .modal-title {
        color: #be9e53;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 400;
    }
    
    .close {
        color: #999595 !important;
        font-size: 50px !important;
        margin-top: -30px !important;
        text-shadow: none !important;
    }
    
    .modal-header,
    .modal-footer {
        border: 0px !important;
    }
    
    .form-group {
        margin-bottom: 1rem;
    }
    
    .form-control {
        border: 1px solid #555555 !important;
        border-radius: 0 !important;
        box-shadow: none;
        outline: none;
        color: #747c84 !important;
        background-color: transparent !important;
        font-family: "Raleway", sans-serif !important;
        font-size: 14px !important;
        letter-spacing: 0.5px;
        transition: all ease 0.5s !important;
        /* color: rgba(255, 255, 255, 0.7411764705882353) !important; */
    }
    
    .form-control:focus {
        box-shadow: none !important;
        outline: none !important;
        background-color: transparent !important;
        border: 1px solid #7d6a3d !important;
        color: rgba(255, 255, 255, 0.7411764705882353) !important;
    }
    
    .form-control.error {
        border: 1px solid #9e3838;
        -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        perspective: 1000px;
    }
    
    .modal-body p {
        color: #c6c6c6;
        font-family: "Raleway", sans-serif !important;
        font-size: 15px;
    }
    
    .radio-button-wrap input[type="radio"] {
        /* width: 80px; */
        height: 40px;
        transition: 0.2s;
        cursor: pointer;
    }
    
    .radio-button-wrap .pretty .state label {
        padding-top: 6px;
        color: #777777;
        font-family: "Raleway", sans-serif !important;
        font-size: 14px;
        width: 80px;
        text-align: center;
        text-indent: 0;
        letter-spacing: 0.5px;
        /* height: 40px; */
    }
    
    .radio-button-wrap .pretty.p-default {
        margin-right: 6px;
        height: 45px;
    }
    
    .radio-button-wrap {
        margin-bottom: 7px;
        padding-top: 2px;
    }
    
    .radio-button-wrap .pretty.p-default input:checked~.state label:after {
        transform: scale(1);
        width: 81px;
        border: 2px solid #be9e53 !important;
        background-color: transparent !important;
        height: 40px;
        top: -7px;
    }
    
    .radio-button-wrap .pretty.p-default input:checked~.state label {
        color: #be9e53;
        width: 80px;
    }
    
    .radio-button-wrap .pretty .state label:before {
        border: 1px solid #555555;
        height: 40px;
        width: 80px;
        top: -7px;
    }
    
    .radio-button-wrap .pretty.big .state label {
        width: 140px;
    }
    
    .radio-button-wrap .pretty.big .state label:before {
        width: 140px;
    }
    
    .radio-button-wrap .pretty.p-default.big input:checked~.state label:after {
        width: 140px !important;
    }
    
    .radio-button-wrap .pretty.p-default.big input:checked~.state label {
        width: 140px;
    }
    
    
    /* ---------------- checkbox --------------------- */
    
    .checkbox-wrap .pretty.p-default input:checked~.state label:after {
        background-color: #be9e53 !important;
        width: 14px;
        height: 14px;
        border: 0;
        transform: scale(1) !important;
    }
    
    .checkbox-wrap .pretty.p-default.p-thick .state label:after,
    .checkbox-wrap .pretty.p-default.p-thick .state label:before {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #555555;
        border: 0;
    }
    
    .checkbox-wrap {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        margin-bottom: 12px;
    }
    
    .checkbox-wrap label {
        color: #777777;
        font-family: "Raleway", sans-serif !important;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 8px;
        vertical-align: super;
    }
    
    .checkbox-wrap .pretty {
        margin-top: 7px;
    }
    
    .pretty.p-icon input:checked~.state .icon {
        color: #fff;
        font-size: 12px;
        top: -4px;
        left: 0px;
    }
    
    footer p,
    footer ul li,
    footer ul li a,
    footer .links {
        text-align: right;
    }
    
    .grecaptcha-badge {
        position: absolute !important;
        width: 302px;
        height: 76px;
        display: block;
        transition: left 0.3s ease 0s;
        position: fixed;
        bottom: 52px;
        left: 55px;
        box-shadow: gray 0px 0px 5px;
        border-radius: 2px;
        overflow: hidden;
    }
    
    button#submit {
        width: 15% !important;
        margin-left: 15px !important;
        margin-top: 40px !important;
    }
    
    .btn-tp-dynamic {
        background-color: #be9e53;
        border-radius: 0;
        min-width: 125px;
        color: #fff;
        font-family: "Raleway", sans-serif !important;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-size: 13px;
        text-align: left;
        outline: none;
        box-shadow: none;
        padding: 0.475rem 0.75rem;
        transition: all ease 0.5s;
    }
    
    .btn-tp-dynamic i {
        float: right;
        margin-top: 2px;
    }
    
    .btn-tp-dynamic:focus {
        outline: none;
        box-shadow: none;
    }
    
    .btn-tp-dynamic:hover {
        background-color: #a78840;
    }
    
    .carousel-caption img {
        -webkit-animation-delay: 1s;
                animation-delay: 1s;
    }
    
    .carousel-caption h3 {
        -webkit-animation-delay: 2s;
                animation-delay: 2s;
    }
    
    .carousel-caption button {
        -webkit-animation-delay: 3s;
                animation-delay: 3s;
    }
    
    #back-to-top {
        position: fixed;
        background: #a0916e !important;
        border-color: #a0916e !important;
        color: #fff !important;
        box-shadow: 0 0 0 0.2rem rgb(248 249 250 / 0%);
        bottom: 25px;
        right: 25px;
        display: none;
    }
    
    .custom-video-div.videobg {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: -1;
        background: #000;
    }
    
    .custom-video-div.videobg iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        /* height: 100vh; */
        height: 56.25vw;
        transform: translate(-50%, -50%);
        border: 0 none;
    }
    
    .slider-rise-logo {
        -webkit-animation-delay: 4s !important;
                animation-delay: 4s !important;
    }
    
    .slider-rise-description {
        -webkit-animation-delay: 5.5s !important;
                animation-delay: 5.5s !important;
    }
    
    .slider-rise-btn {
        -webkit-animation-delay: 6.5s !important;
                animation-delay: 6.5s !important;
    }
    
    .first-slider-carousel {
        -webkit-animation-delay: 6.5s !important;
                animation-delay: 6.5s !important;
    }
    
    .home-slider-wrap img.d-block {
        height: 100vh !important;
        -o-object-fit: cover;
           object-fit: cover;
    }
    
    .carousel-caption {
        top: 40% !important;
    }
    
    
    /* .first-slider-carousel {
        position: relative;
        display: none;
        -webkit-transition: 18s ease-in-out left !important;
        -moz-transition: 18s ease-in-out left !important;
        -o-transition: 18s ease-in-out left !important;
        transition: 18s ease-in-out left !important;
    } */
    
    
    /* .first-slider-carousel {
        transition-delay: 15s;
    } */
    
    section.history-section-02 .d-table {
        /* min-height: 530px; */
        min-height: 100%;
        width: 100%;
    }
    
    .d-table {
        display: table!important;
    }
    
    .align-middle {
        vertical-align: middle!important;
        margin: auto;
    }
    
    .d-table-cell {
        display: table-cell!important;
    }
    
    .gold-line {
        border-bottom: 8px solid #a0916e !important;
    }
    
    .col-md-6.left-box-text {
        padding-left: 60px;
    }
    
    .history-section-02,
    .overview-section-2 {
        padding-bottom: 70px !important;
    }
    
    .line-h-60 {
        line-height: 60px !important;
    }
    
    section.overview-section-2 .content-box {
        height: auto;
        max-width: 760px !important;
        padding-left: 60px !important;
        padding-top: 87px;
    }
    .box-future-img-wrap {
      background-image: url(/public/mission/img-future-projects.jpg);
      position: relative;
      min-height: 460px;
      background-position: center center;
    }
    
    .box-current-img-wrap {
      background-image: url(/public/mission/img-currnet-proj.jpg);
      position: relative;
      min-height: 460px;
      background-position: center center;
    }
    
    section.mission-section-01 {
        background-image: url(/public/mission/mission-img-1.jpg);
        width: 100%;
        min-height: 900px;
        height: auto;
        background-size: cover;
    }
    
    .mission-section-03 {
        background-image: url(/public/mission/tpd-technology.jpg);
        min-height: 800px;
        height: auto;
        background-size: cover;
        position: relative;
    }
    
    .mission-section-04 {
        background-image: url(/public/mission/tpd-talent.jpg);
        min-height: 800px;
        height: auto;
        background-size: cover;
        position: relative;
    }
    
    .mission-section-04 .contect-nission-box {
        position: absolute;
        bottom: 25px;
        right: 40px;
        text-align: right;
    }
    
    .mission-section-05 {
        background-image: url(/public/mission/tpd-transform.jpg);
        min-height: 800px;
        height: auto;
        background-size: cover;
        position: relative;
    }
    
    .mission-section-05-padding {
        padding: 30px 30px 0px;
    }
    
    .mission-section-03 .contect-nission-box {
        position: absolute;
        bottom: 30px;
        left: 40px;
    }
    
    .mission-section-05 .contect-nission-box {
        position: absolute;
        bottom: 30px;
        left: 40px;
    }
    
    p.heading-h1.mb-0 {
        line-height: 40px;
        float: right;
    }
    
    div#rise p.heading-h1 {
        margin: 0px;
        line-height: 78px;
    }
    
    div#rise .p-content {
        height: 226px;
        max-width: 615px;
    }
    
    div#rise .content-box {
        padding-top: 70px;
    }
    
    .team-links-wrap {
        display: flex;
        justify-content: center;
        /* padding: 10px; */
        align-items: center;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
    }
    
    .team-links-wrap p {
        padding: 20px 30px;
    }
    
    .team-img-full-wrap {
        position: relative;
    }
    
    .img-bottom-description-wrap {
        opacity: 0;
        transition: 0.7s;
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: block;
        background: #20221D;
    }
    
    .team-img-full-wrap:hover .img-bottom-description-wrap {
        opacity: 0.7;
    }
    
    .team-member-name-wrap {
        padding: 20px 0px 20px 20px;
    }
    
    .team-heading-wrap {
        text-align: center;
        padding-bottom: 40px;
        padding-top: 5px;
    }
    
    .team-heading-wrap .paraf-2-gray {
        width: 50%;
        margin: 0px auto;
    }
    
    .team-mainfull-wrap {
        background-color: #20221d;
        padding-top: 80px;
        padding-bottom: 0px;
        /* padding-bottom: 40px; */
    }
    
    .team-details-full-wrap .col-md-4 {
        padding-bottom: 30px;
    }
    
    .family-con-box .heading-h2-gray a {
        font-size: 24px !important;
    }
    
    .team-details-full-wrap .heading-h2-foot {
        letter-spacing: 2.28px !important;
    }
    
    .img-bottom-description-wrap .heading-h2-gray {
        font-size: 24px !important;
    }
    
    .img-bottom-description-wrap .paraf-1 {
        font-size: 16px !important;
    }
    
    .contact-field-back {
        height: 56px;
        background-color: #e5e5e5 !important;
        border: 1px solid #707070;
        opacity: 1;
    }
    
    .contact-field-back-txtarea {
        background-color: #e5e5e5 !important;
        border: 1px solid #707070;
        opacity: 1;
    }
    
    
    /*sunanda*/
    
    
    /* div#session_message {
        text-align: center !important;
        margin: auto !important;
        display: block !important;
    } */
    
    .irs--flat .irs-line {
        height: 6px !important;
        background-color: #303030 !important;
    }
    
    .irs--flat .irs-bar {
        height: 6px !important;
        background-color: #be9e53 !important;
    }
    
    .irs--flat .irs-handle>i:first-child {
        width: 15px !important;
        height: 15px !important;
        border-radius: 50% !important;
        top: -2px !important;
        margin-left: -5px !important;
        background-color: #be9e53 !important;
    }
    
    .irs--flat .irs-from,
    .irs--flat .irs-to,
    .irs--flat .irs-single {
        background-color: #5e5e5e !important;
        color: #c6c6c6 !important;
        font-size: 12px !important;
        font-family: "Raleway", sans-serif !important;
        padding: 4px 4px 3px 4px !important;
        top: -7px !important;
    }
    
    .irs--flat .irs-from:before,
    .irs--flat .irs-to:before,
    .irs--flat .irs-single:before {
        border-top-color: #5e5e5e !important;
    }
    
    .irs--flat .irs-min,
    .irs--flat .irs-max {
        color: #333 !important;
        font-size: 14px !important;
        font-family: "Raleway", sans-serif !important;
        line-height: 1;
        top: -8px !important;
        padding: 3px 5px !important;
        background-color: rgba(0, 0, 0, 0.1) !important;
        border-radius: 4px !important;
    }
    
    .irs-grid-text {
        font-size: 12px !important;
        font-family: "Raleway", sans-serif !important;
    }
    
    .irs-grid {
        bottom: 4px !important;
    }
    
    
    /* img.animate__animated.animate__fadeInUp.animate__delay-0\.5s {
        opacity: 75% !IMPORTANT;
    } */
    
    .projects-residential-full-wrap .content-box,
    .projects-commercial-full-wrap .content-box,
    .overview-section-2 .col-md-6.content-box {
        padding-top: 0px !important;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    
    /*sunanda*/
    
    div#session_message {
        margin-left: -17px !important;
    }
    
    div#session_message_new_content {
        margin-left: 17px !important;
    }
    
    .grecaptcha-badge:hover,
    .grecaptcha-badge {
        bottom: 85px !important;
    }
    
    .footer-logo-wrap {
        text-align: center;
    }
    
    .footer-bootom-last-wrap ul {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    
    
    /*sunnada*/
    
    img.footer-image-one {
        width: auto;
        height: 50px;
    }
    
    option.select-option {
        background-color: #1b1b1b !important;
    }
    
    .modal-content.iframe {
        background-color: #1b1b1b!important;
        overflow: hidden;
        padding: 15px !important;
    }
    
    button.close.new-section:focus {
        outline: inherit !IMPORTANT;
        outline: inherit !important;
    }
    
    .new-section {
        float: right;
        font-size: 1.5rem;
        font-weight: inherit !IMPORTANT;
        line-height: 0 !important;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: inherit !IMPORTANT;
    }
    
    span.cancel-button {
        position: absolute !IMPORTANT;
        background: white !important;
        border-radius: 20px !IMPORTANT;
    }
    
    .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0.6rem !IMPORTANT;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }
    
    .news-inner-con ul li::before {
        content: "\2022";
        color: #a0916e !important;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
    
    .coles-img-anchr {
        -webkit-animation-delay: 3s !important;
                animation-delay: 3s !important;
    }
    
    .coles-discover-btn-wrap {
        -webkit-animation-delay: 4.5s !important;
                animation-delay: 4.5s !important;
    }
    .section-padding {
        padding-top: 15px;
    }
    .heding-news-tag{
        font-family: "Raleway", sans-serif !important;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0.18px;
        color: #bebebe;
    }
    .left-class {
        display: flex;
        flex-direction: row-reverse;
    }
    .mobile-only {
        display: none !important;
    }
    
    
    /*
      ##Device = Desktops
      ##Screen = 1281px to higher resolution desktops
    */
    
    @media (min-width: 1601px) {
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    @media (min-width: 2500px) {}
    
    @media (min-width: 1600px) and (max-width: 1700px) {}
    
    @media (min-width: 1281px) and (max-width: 1600px) {
        .news-new-content-box .heading-h2 a {
            font-size: 25px !important;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    @media only screen and (min-width: 1370px) and (max-width: 1605px) {
        /* STYLES GO HERE */
        .progressBarContainer div {
            width: 14%;
        }
        .pro-info-main-container {
            padding: 0 100px 0 100px !important;
        }
        .news-new-content-box .heading-h2 a {
            font-size: 26px !important;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
        .news-new-content-box .heading-h2 a {
            font-size: 25px !important;
        }
        .col-md-5.img-person {
            background-position: top !important;
        }
        .mission-section-05 {
            min-height: 618px;
        }
        section.mission-section-01 {
            min-height: 590px;
        }
        .mission-section-03,
        .mission-section-04 {
            min-height: 550px;
        }
        .row.inner-more-projects .col-lg-3 {
            flex: 0 0 22%;
            max-width: 22%;
        }
        section.overview-section-2 p.heading-h1 {
            font-size: 36px;
            letter-spacing: 1.96px;
            line-height: 40px;
        }
        /*sunanda*/
        section.overview-section-2 .content-box {
            /* height: 390px; */
            max-width: 760px !important;
            padding-left: 60px !important;
            padding-top: 10px;
        }
        /*sunanda*/
        .row.inner-more-projects .foter-slash {
            margin-right: 65px;
        }
        .projects-commercial-full-wrap .content-box .heading-h1,
        .projects-residential-full-wrap .content-box .heading-h1 {
            line-height: 66px !important;
        }
        .commerc-main-head {
            padding-bottom: 20px;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    @media only screen and (min-width: 900px) and (max-width: 1600px) {
        .news-new-content-box .heading-h2 a {
            font-size: 25px !important;
        }
        section.overview-section-2 .content-box {
            /* height: 460px; */
            max-width: 760px !important;
            padding-left: 60px !important;
        }
        .paraf-2-gray,
        .first-bar::after,
        .box-bottom-para-wrap p,
        .home-completed-project-title-wrap p,
        .our-values-list-wrap ol p {
            font-family: "Raleway", sans-serif !important;
            font-size: 17px;
            font-weight: 300;
            letter-spacing: 0.18px;
            color: #bebebe;
        }
        .mission-section-05 {
            min-height: 740px;
        }
        section.mission-section-01 {
            min-height: 740px;
        }
        .mission-section-03,
        .mission-section-04 {
            min-height: 700px;
        }
        section.overview-section-2 p.heading-h1 {
            font-size: 38px;
            letter-spacing: 1.96px;
            line-height: 40px;
        }
        .row.inner-more-projects .col-lg-3 {
            flex: 0 0 22%;
            max-width: 22%;
        }
        .row.inner-more-projects .foter-slash {
            margin-right: 50px;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    @media only screen and (min-width: 800px) and (max-width: 1280px) {
        .col-md-5.img-person {
            background-position: top !important;
        }
        section.overview-section-2 .content-box {
            /* height: 365px; */
            max-width: 760px !important;
            padding-left: 60px !important;
            padding-top: 35px;
        }
        section.overview-section-2 p.heading-h1 {
            font-size: 34px;
            letter-spacing: 1.96px;
        }
        #rise .box-bottom-btn-wrap {
            margin-top: 15px !important;
        }
        .commerc-main-head {
            padding-bottom: 20px;
        }
        div#rise .p-content {
            height: 260px;
            max-width: 615px;
        }
        .heading-h1,
        .home-completed-project-title-wrap h1 {
            line-height: 55px !important;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    
    /* For 1366 Resolution */
    
    @media only screen and (min-width: 1030px) and (max-width: 1366px) {
        .col-md-5.img-person {
            background-position: top !important;
            min-height: 545px !important;
        }
        .progressBarContainer div {
            width: 14%;
        }
        .img-person {
            background-size: contain;
        }
        .news-list-wrap ul li {
            position: relative;
            line-height: 20px;
        }
        .heading-h1-wht,
        .box-title-wrap h1,
        .heading-h1,
        .home-completed-project-title-wrap h1 {
            font-size: 42px;
            margin-top: 10px;
        }
        .pro-info-main-container {
            padding: 0 60px 0 60px !important;
        }
        .news-full-wrap .header-content-box .heading-h1 {
            line-height: 34px !important;
        }
        .news-full-wrap .paraf-4 {
            font-size: 16px !important;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    
    /*
      ##Device = Laptops, Desktops
      ##Screen = B/w 1025px to 1280px
    */
    
    @media (min-width: 1025px) and (max-width: 1280px) {
        .col-md-5.img-person {
            background-position: top !important;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    
    /*
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        /*sunnada*/

        .text h2{
            color: #fff !important;
            width: 60% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 30px !important;
            line-height: 50px !important;
            font-family: Raleway,sans-serif!important;
            }





        .footer-logo-wrap img {
            width: 20% !important;
        }
        .carousel-caption.wish-logo {
            margin-top: -20%;
        }
        .mission-section-05 {
            min-height: 350px;
        }
        .mission-section-04,
        .mission-section-03 {
            min-height: 340px;
        }
        .mission-section-02 .justify-content-center.text-center {
            width: 80%;
            margin: auto;
        }
        .mission-section-04 .heading-h1,
        .mission-section-03 .heading-h1.line-height,
        .mission-section-05 .heading-h1 {
            line-height: 27px !important;
        }
        section.mission-section-01 {
            min-height: 350px;
        }
        .tab-reverse-col {
            display: flex !important;
            flex-direction: column-reverse !important;
        }
        section.overview-section-2 .row .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        section.overview-section-2 .row .col-md-6.content-box {
            height: 355px;
            padding-left: 40px !important;
            padding-top: 40px !important;
            padding-bottom: 40px;
        }
        .overview-section-2 {
            padding-bottom: 30px !important;
        }
        section.overview-section-2 .content-box .heading-h1 {
            font-size: 30px !important;
            letter-spacing: 1.96px;
            line-height: 30px;
            margin-bottom: 15px;
        }
        .pb-100 {
            padding-bottom: 60px !important;
        }
        .history-section-02 {
            padding-bottom: 40px !important;
        }
        .pt-100 {
            padding-top: 60px !important;
        }
        section.history-section-02 .d-table {
            min-height: 220px;
            width: 100%;
        }
        .carousel-caption.wish-logo.slider-custom-caption img {
            width: 65%;
        }
        .heading-h1-wht,
        .box-title-wrap h1,
        .heading-h1,
        .home-completed-project-title-wrap h1 {
            font-size: 34px;
            line-height: 56px !important;
        }
        .paraf-2-gray,
        .first-bar::after,
        .box-bottom-para-wrap p,
        .home-completed-project-title-wrap p {
            font-size: 14px;
        }
        .paraf-3-wht,
        .progressBarContainer .item h3,
        .paraf-4,
        .progressBarContainer .item p {
            font-size: 14px;
        }
        .paraf-5-gray,
        footer p,
        footer ul li,
        footer ul li a,
        footer .links,
        .paraf-3,
        footer h3 {
            font-size: 10px;
        }
        .img-box-wrap {
            min-height: 370px;
        }
        .home-team-full-wrap .box-title-wrap h1 {
            font-size: 24px;
        }
        .gold-btn,
        .box-bottom-btn-wrap a {
            font-size: 8px;
            padding: 12px 16px 12px 16px;
        }
        .box-title-wrap {
            padding-left: 15px;
        }
        .box-bottom-wrap {
            left: 15px;
        }
        .footer-logo-wrap img {
            width: 85%;
        }
        .news-list-wrap ul li {
            line-height: 18px;
        }
        .custom-nav-button.custom-padding-right5 {
            padding-right: 7vw;
            padding-top: 20px;
        }
        .img-person {
            margin-left: 2% !important;
            min-height: 350px !important;
            width: 350px !important;
            background-size: 100%;
        }
        .con-content {
            margin: 36px 60px 0 60px;
            width: 100%;
        }
        .pro-info-main-container {
            padding: 0 40px 0 40px !important;
        }
        .modal-dialog {
            text-align: left;
            top: 10% !important;
            vertical-align: middle;
        }
        .team-links-wrap {
            justify-content: center;
            padding-bottom: 15px;
            align-items: center;
            flex-direction: row;
            align-content: center;
            flex-wrap: wrap;
        }
        .team-links-wrap p {
            padding: 0px 30px;
        }
        .team-heading-wrap .paraf-2-gray {
            width: 70%;
        }
        .img-bottom-description-wrap {
            position: relative;
            opacity: 1 !important;
        }
        .team-member-name-wrap {
            padding: 20px 0px 20px 0px;
        }
        .team-mainfull-wrap p.heading-h2-gray,
        .team-mainfull-wrap .heading-h2-gray a {
            font-size: 20px !important;
        }
        .team-mainfull-wrap .justify-content-center .heading-h2-foot {
            font-size: 26px;
        }
        .team-mainfull-wrap .paraf-1 {
            font-size: 14px !important;
        }
        .tablet-portrait100 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .box-lg-btn a,
        .box-sml-btn a {
            height: 35px;
        }
        .projects-residential-full-wrap .first-col-6 {
            height: 153px !important;
            padding-top: 50px !important;
        }
        .projects-residential-full-wrap .second-col-6 {
            height: 340px !important;
            padding-left: 45px !important;
        }
        .projects-commercial-full-wrap .first-col-6 {
            height: 153px !important;
            padding-top: 50px !important;
        }
        .projects-commercial-full-wrap .second-col-6 {
            height: 180px !important;
            padding-left: 45px !important;
        }
        .resident-project .row.justify-content-center,
        .projects-residential .row.justify-content-center {
            justify-content: left !important;
        }
        .tp-team-fullwrap .team-header2 {
            padding-left: 0px !important;
            padding-top: 45px;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    
    /*
      ##Device = Tablets, Ipads (landscape)
      ##Screen = B/w 768px to 1024px
    */
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    
    
    
        /*sunnada*/
        .footer-logo-wrap img {
            width: 20% !important;
        }
        .first-bar::after {
            top: -7px;
        }
        .mission-section-05 {
            min-height: 470px;
        }
        .mission-section-04,
        .mission-section-03 {
            min-height: 450px;
        }
        .mission-section-02 .justify-content-center.text-center {
            width: 80%;
            margin: auto;
        }
        section.mission-section-01 {
            min-height: 480px;
        }
        section.overview-section-2 .content-box {
            height: 420px;
            padding-left: 40px !important;
            padding-top: 30px;
        }
        section.overview-section-2 .tablet-landscape img {
            height: 420px;
            -o-object-fit: cover;
               object-fit: cover;
        }
        section.overview-section-2 .content-box .heading-h1 {
            font-size: 30px !important;
            letter-spacing: 1.96px;
            line-height: 30px;
            margin-bottom: 0px;
            /* margin-bottom: 15px; */
        }
        p.heading-h1.line-height {
            line-height: 40px !important;
        }
        .history-section-02 h1.heading-h1.line-height {
            line-height: 28px !important;
        }
        .history-section-02 h4.paraf-2-gray.color-gold {
            line-height: 22px;
        }
        .pb-100 {
            padding-bottom: 60px !important;
        }
        .history-section-02,
        .overview-section-2 {
            padding-bottom: 40px !important;
        }
        .pt-100 {
            padding-top: 60px !important;
        }
        section.history-section-02 .d-table {
            min-height: 295px;
            width: 100%;
        }
        .img-person {
            background-size: contain;
        }
        .custom-nav-button.custom-padding-right5 {
            padding-right: 7vw;
        }
        .home-team-full-wrap .box-title-wrap h1,
        .heading-h1-wht,
        .box-title-wrap h1,
        .heading-h1,
        .home-completed-project-title-wrap h1 {
            font-size: 32px;
        }
        .paraf-2-gray,
        .first-bar::after,
        .box-bottom-para-wrap p,
        .home-completed-project-title-wrap p {
            font-size: 14px;
        }
        .paraf-3,
        footer h3 {
            font-size: 12px;
        }
        .paraf-5-gray,
        footer p,
        footer ul li,
        footer ul li a,
        footer .links {
            font-size: 10px;
        }
        .footer-logo-wrap img {
            width: 85%;
        }
        .news-list-wrap ul li {
            line-height: 22px;
        }
        .img-person {
            margin-left: 2% !important;
            min-height: 350px !important;
            width: 350px !important;
        }
        .con-content {
            margin: 36px 60px 0 60px;
            width: 100%;
        }
        .pro-info-main-container {
            padding: 0 40px 0 40px !important;
        }
        .slider-custom-caption img {
            width: 48%;
        }
        .team-links-wrap {
            justify-content: center;
            padding-bottom: 15px;
            align-items: center;
            flex-direction: row;
            align-content: center;
            flex-wrap: wrap;
        }
        .team-links-wrap p {
            padding: 0px 30px;
        }
        .team-heading-wrap .paraf-2-gray {
            width: 70%;
        }
        .img-bottom-description-wrap {
            position: relative;
            opacity: 1 !important;
        }
        .team-member-name-wrap {
            padding: 20px 0px 20px 0px;
        }
        .team-mainfull-wrap p.heading-h2-gray,
        .team-mainfull-wrap .heading-h2-gray a {
            font-size: 20px !important;
        }
        .team-mainfull-wrap .justify-content-center .heading-h2-foot {
            font-size: 26px;
        }
        .team-mainfull-wrap .paraf-1 {
            font-size: 14px !important;
        }
        img.slider-mobile.d-block {
            display: none !important;
        }
    }
    
    
    /*
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */
    
    @media (min-width: 481px) and (max-width: 767px) {
        .pro-info-main-container {
            padding: 0 40px 0 40px !important;
        }
        .pro-info-right-con {
            padding: 35px 0 0 30px !important;
            max-width: 759px !important;
            width: 100%;
        }
        .foter-container {
            max-width: 1850px !important;
            width: 100%;
            background-color: #20221d;
            margin-top: 95px;
            padding: 77px 30px 77px 30px;
        }
        .news-inner-con {
            max-width: 1518px;
            width: 100%;
            background-color: #20221d;
            padding: 16px 60px;
        }
        .foter-slash {
            display: none;
        }
        .modal-dialog {
            text-align: left;
            top: 10% !important;
            vertical-align: middle;
        }
    }
    
    
    /*
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */
    
    @media (min-width: 320px) and (max-width: 480px) {

        
        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }
    
    
    
        .grecaptcha-badge {
            position: absolute !important;
            width: 302px;
            height: 76px;
            display: block;
            transition: left 0.3s ease 0s;
            position: fixed;
            bottom: 52px;
            left: 18px;
            box-shadow: grey 0px 0px 5px;
            border-radius: 2px;
            overflow: initial;
        }
        .grecaptcha-badge:hover, .grecaptcha-badge {
            bottom: 70px !important;
        }
        button#submit {
            margin-top: 50px;
            width: 15% !important;
            margin-left: 15px !important;
        }
    
    
    
    
        .desktop-hide {
            display: none;
        }
    }
    
    
    /* Nexus 5x */
    
    @media only screen and (min-width: 361px) and (max-width: 411px) and (min-height: 641px) and (max-height: 731px) and (orientation: portrait) {
        
        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }
    }
    
    @media only screen and (min-height: 361px) and (max-height: 411px) and (min-width: 641px) and (max-width: 731px) and (orientation: landscape) {
        
        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }
    }
    
    
    /* Nexus 6p */
    
    @media only screen and (min-width: 412px) and (max-width: 435px) and (min-height: 732px) and (max-height: 773px) and (orientation: portrait) {
        
        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }
    }
    
    @media only screen and (min-height: 412px) and (max-height: 435px) and (min-width: 732px) and (max-width: 773px) and (orientation: landscape) {}
    
    
    /* iPhone 5/5s */
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {

        img.scroll-down-image {
            position: fixed;
            top: 90%;
            width: 11%;
            left: 53.5%;
            transform: translate(-50%, -50%);
        }

        .p-content-vision {
            padding-top: 0px;
            padding-left: 29px;
            height: auto;
            max-width: 100%;
        }

        
        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }

        img.d-block.slider-desktop {
            display: none !important;
        }
        #carouselExampleCaptions .carousel-inner .d-block.slider-mobile {
            display: block !important;
        }
        /*sunanda*/
        footer.custom-padding-left3.custom-padding-right5 {
            margin-top: 0px !important;
            padding-top: 40px !important;
        }
        footer p,
        footer ul li,
        footer ul li a,
        footer .links {
            text-align: center;
        }
        .col-md-6.content-box.pro-com-box-img {
            /* height: 534px !important; */
            height: 320px !important;
            max-width: 760px !important;
            padding-left: 60px !important;
            padding-top: 55px !important;
            background-size: cover;
            background-position: center center;
        }
        div#rise .content-box {
            padding-top: 40px;
        }
        div#rise .p-content {
            height: auto;
            max-width: 615px;
        }
        .col-md-6.content-box.resident-title {
            padding-left: 40px !important;
            padding-top: 15px;
        }
        .projects-com-box .content-box {
            height: auto;
            max-width: 760px !important;
            padding-left: 44px !important;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .desktop-hide {
            display: none;
        }
        .projects-residential .content-box-2 {
            padding-top: 0px !important;
        }
        .paraf-2-gray a,
        .box-bottom-para-wrap p a,
        .home-completed-project-title-wrap p a {
            color: #bebebe;
        }
        .nav-contact.bottom-heading-nav {
            margin-left: -46px !important;
            padding-top: 10px;
        }
        .All-news-section .contenent-box {
            width: 100%;
            height: auto;
            padding-top: 12px;
            padding-bottom: 12px;
        }
        .All-news-section .news-con {
            padding: 0 0 40px 0;
        }
        .header-content-box.news-section {
            padding: 40px 15px 20px 15px;
        }
        .projects-residential .content-box {
            height: auto;
            max-width: 100% !important;
            padding-left: 15px !important;
            padding-top: 15px;
        }
        section.mission-section-01,
        .mission-section-03,
        .mission-section-04,
        .mission-section-05 {
            min-height: auto;
        }
        section.mission-section-01 .col-11 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .mission-section-03 .contect-nission-box,
        .mission-section-05 .contect-nission-box {
            position: relative;
            bottom: 0px;
            left: 0px;
            padding: 30px 0px;
        }
        .mission-page .paraf-2-gray {
            color: #ffffff;
        }
        .mission-page .heading-h1 {
            color: #cfb780;
        }
        .mission-section-04 .contect-nission-box {
            position: relative;
            bottom: 0px;
            left: 0px;
            padding: 30px 0px;
            text-align: left;
        }
        .mission-section-05-padding {
            padding: 15px 15px 0px;
        }
        .mission-section-02 .text-center {
            text-align: left !important;
        }
        section.mission-section-01 p.heading-h1.mb-0 {
            line-height: 40px;
            float: none;
        }
        /* .overview-section-2 {
            padding-bottom: 20px !important;
        } */
        /*sunanda*/
        .overview-section-2 {
            padding-bottom: 0px !important;
        }
        section.overview-section-1 p.heading-h1.line-h-60 {
            padding: 0px 30px;
            line-height: 18px !important;
        }
        section.overview-section-1 p.heading-h1 {
            letter-spacing: 1.96px !important;
        }
        section.overview-section-2 .content-box {
            min-height: 420px !important;
            height: auto !important;
            max-width: 760px !important;
            padding-left: 50px !important;
            padding-top: 40px;
        }
        .mobile-reverse-col {
            display: flex !important;
            flex-direction: column-reverse !important;
        }
        .col-md-6.left-box-text {
            padding-left: 15px;
        }
        .pb-100 {
            padding-bottom: 40px !important;
        }
        .pt-100 {
            padding-top: 40px !important;
        }
        p.heading-h1.line-height {
            line-height: 30px !important;
        }
        .history-section-02 h1.heading-h1.line-height {
            line-height: 28px !important;
        }
        .history-section-02 {
            padding-bottom: 0px !important;
        }
        .history-section-02 h4.paraf-2-gray.color-gold {
            line-height: 22px;
        }
        .history-section-02 .col-12.history-inv-p-0 {
            padding: 0px 15px !important;
        }
        /* section.history-section-02 .d-table {
            min-height: 160px;
            width: 100%;
        } */
        /*sunanda*/
        section.history-section-02 .d-table {
            min-height: 200px;
            width: 100%;
        }
        .about-progress-wrap-text .heading-h1 {
            font-size: 40px;
        }
        div#history .content-box {
            height: 440px;
        }
        .tp-family .col-md-6:first-child.content-sml-box {
            height: auto;
            padding-top: 40px;
        }
        .tp-team-maintext-paraf .ban-para p br {
            display: none;
        }
        .tp-team-maintext-paraf .ban-para {
            max-height: 160px;
            margin-top: 10px;
            padding: 0px 25px;
        }
        .container-fluid.tp-team-maintext .ban-header {
            margin-top: 50px;
        }
        .container-fluid.tp-team-maintext {
            margin-bottom: 35px;
        }
        .tp-team-maintext .ban-header {
            margin-top: 48px;
        }
        .fam-con-img {
            height: 290px !important;
        }
        /* .family-con-right-box {
            padding: 35px 20px 54px 35px !important;
        } */
        .family-con .heading-h2-gray a {
            font-size: 22px;
        }
        .resident-project .col-md-6:first-child.content-box {
            height: auto;
        }
        .resident-project p.heading-h1,
        .resident-main-head .commerc-main-head {
            line-height: 10px !important;
        }
        /* .projectsall-details-section .col-md-6:last-child.content-box {
            height: auto;
            max-width: 760px !important;
            padding-left: 60px !important;
            padding-top: 50px;
            padding-bottom: 50px;
        } */
        /*sunanda*/
        .projectsall-details-section .col-md-6:last-child.content-box {
            height: auto;
            max-width: 760px !important;
            padding-left: 25px !important;
            padding-top: 30px;
            padding-bottom: 50px;
        }
        /* .resident-project .col-md-6:last-child.content-box{
            height: auto;
            max-width: 760px !important;
            padding-left: 30px !important;
            padding-top: 0px !important;
        } */
        .projects-all .img-box {
            min-height: 293px !important;
        }
        /* .family-con-box {
            padding: 35px 0 0 35px !important;
            z-index: 9;
        } */
        .tp-family .col-md-6:last-child.content-sml-box {
            height: auto;
            padding-left: 28px !important;
            padding-top: 0px;
        }
        .col-md-6.content-box.team-main-paraf-content {
            padding-left: 32px !important;
            padding-top: 0px;
            height: auto;
        }
        .team-main-paraf .content-box.co1-head-01 {
            height: auto;
        }
        div#overview .content-box {
            height: 379px;
        }
        .about-progress-wrap-text .about-drag-text-h1 {
            margin-bottom: 30px;
        }
        .content-box.top-contentbox-wrap p.heading-h1.mb-0 {
            line-height: 37px;
        }
        /* .content-box {
            height: 534px;
            max-width: 760px !important;
            padding-left: 60px !important;
            padding-top: 55px;
        } */
        /*sunnada*/
        .content-box {
            height: 465px;
            max-width: 760px !important;
            padding-left: 60px !important;
            padding-top: 55px;
        }
        .carousel-caption.wish-logo img {
            margin-top: -6vh !important;
            width: 45%;
        }
        h1.banner-text-content.mb-0 {
            font-size: 14px;
        }
        .home-middle-slider-wrap {
            padding-bottom: 35px;
        }
        .progressBarContainer {
            display: none;
        }
        .pro-info-main-container {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
        .our-values-list-wrap ol {
            display: block;
            padding-bottom: 30px;
        }
        .our-values-list-wrap {
            padding-bottom: 30px;
        }
        .col-md-6.content-box.top-contentbox-wrap.content-about-2 {
            padding-left: 46px !important;
            margin-top: 0px !important;
            padding-top: 0px !important;
        }
        .content-about-2 p.heading-h1 {
            font-size: 25px;
            line-height: 34px;
        }
        .navbar .navbar-brand {
            width: 150px;
        }
        .pro-info-left-con {
            max-width: 100% !important;
            width: 100%;
        }
        .pro-info-right-con {
            padding: 35px 35px !important;
            max-width: 100% !important;
        }
        .pro-info-header {
            font-size: 30px;
            letter-spacing: 2.96px;
            margin-top: -10px;
            margin-bottom: 30px;
        }
        .pro-info-right-con {
            padding: 35px 35px !important;
        }
        /* .custom-nav-button.custom-padding-right5 {
            padding-right: 15vw;
        } */
        /* .custom-nav-button .navbar-toggler .icon-bar {
            width: 18px;
        } */
        .first-bar::after {
            top: -7px;
        }
        .heading-h1-wht,
        .box-title-wrap h1,
        .heading-h1,
        .home-completed-project-title-wrap h1,
        .home-team-full-wrap .box-title-wrap h1 {
            /* font-size: 32px; */
            font-size: 25px;
            line-height: 35px !important;
            margin-top: 10px !important;
        }
        .paraf-2-gray,
        .first-bar::after,
        .box-bottom-para-wrap p,
        .home-completed-project-title-wrap p {
            font-size: 14px;
        }
        .paraf-3-wht,
        .progressBarContainer .item h3,
        .paraf-4,
        .progressBarContainer .item p {
            font-size: 10px;
        }
        .slick-slide {
            height: 245px !important;
            background-size: cover;
            background-position: center center;
        }
        .carousel-caption.wish-logo {
            margin-top: -15%;
        }
        .carousel-caption.wish-logo img {
            width: 50%;
        }
        .carousel-indicators li {
            width: 5px !important;
            height: 5px !important;
        }
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            width: 20px !important;
            height: 20px !important;
        }
        .footer-about-us-wrap {
            margin-top: 20px;
        }
        .news-list-wrap {
            margin-top: 5px;
        }
        .m-top-30-mobile {
            margin-top: 30px;
        }
        .m-bottom-30-mobile {
            margin-bottom: 30px;
        }
        .p-top-mobile {
            padding-top: 30px;
        }
        .p-bottom-mobile {
            padding-bottom: 30px;
        }
        .img-person {
            margin-left: 0% !important;
            min-height: 365px !important;
            width: 100% !important;
            background-size: 100%;
            margin-bottom: 3%;
        }
        .img-box {
            width: 100%;
            min-height: auto;
            background-repeat: no-repeat;
            position: relative;
            background-position: center center;
        }
        .top-nav-list-wrap .col-md-4 {
            display: block !important;
        }
        .bottom-heading-nav {
            margin-left: 0px !important;
            padding-top: 10px;
        }
        .con-content {
            margin: 36px 30px 0 30px;
            width: 100%;
        }
        p.heading-h1.pro-info-header,
        .heading-h2-foot {
            font-size: 30px;
            letter-spacing: 2.96px;
        }
        p.paraf-2-gray.color-gold.pro-info-adres {
            margin-bottom: 5px;
        }
        .pro-info-title {
            margin-top: 30px;
        }
        .foter-container p.paraf-4 {
            font-size: 14px;
        }
        /* .pro-info-right-con {
        padding: 35px 0 0 30px !important;
        max-width: 759px !important;
        width: 100%;
    } */
        .foter-container {
            /* max-width:1850px !important;
        width: 100%;
        background-color: #20221D;
        margin-top: 95px;
        padding: 20px 20px 77px 20px; */
            max-width: 100% !important;
            width: 100%;
            background-color: #20221d;
            margin-top: 0px;
            padding: 20px 35px;
        }
        .foter-container .row.mt-5 {
            margin-top: 0rem !important;
        }
        .foter-slash {
            display: none;
        }
        .news-inner-con {
            max-width: 1518px;
            width: 100%;
            background-color: #20221d;
            padding: 16px 30px;
        }
        .col-container {
            max-width: 516px !important;
            width: 100%;
            margin: 0 0 0 0;
        }
        .header-content-box {
            padding: 100px 30px 100px 30px;
        }
        .modal-content {
            padding: 10px;
        }
        .modal-dialog {
            text-align: left;
            top: 10% !important;
            vertical-align: middle;
        }
        .slideranimate .panorama-control {
            display: block !important;
        }
        .panorama-viewport,
        .about-progress-wrap {
            height: 440px !important;
        }
        .heading-h2-gray,
        .heading-h2-gray a,
        .our-values-list-wrap ol h5,
        .family-con-box .heading-h2-gray a,
        .img-bottom-description-wrap .heading-h2-gray {
            font-size: 16px !important;
        }
        .custom-video-div.videobg,
        .home-slider-wrap img.d-block {
            height: 95vh !important;
        }
        .carousel-item.first-slider-carousel {
            margin-top: 0% !important;
        }
        .custom-video-div.videobg iframe {
            width: 177.78vh !important;
            height: 95vh !important;
        }
        .carousel-caption.wish-logo {
            margin-top: 0%;
        }
        .team-links-wrap {
            justify-content: center;
            padding-bottom: 15px;
            align-items: center;
            flex-direction: row;
            align-content: center;
            flex-wrap: wrap;
        }
        .team-links-wrap p {
            padding: 0px 15px;
        }
        .team-mainfull-wrap {
            padding-top: 40px;
            padding-bottom: 0px;
        }
        .team-heading-wrap .heading-h2-foot {
            font-size: 26px;
            line-height: 38px;
            width: 80%;
            margin: 0px auto;
            padding-bottom: 10px;
        }
        .team-details-full-wrap {
            border-top: 1px solid #a0916e40;
            border-bottom: 1px solid #a0916e40;
        }
        .team-heading-wrap .paraf-2-gray {
            width: 80%;
        }
        .img-bottom-description-wrap {
            position: relative;
            opacity: 1 !important;
        }
        .team-member-name-wrap {
            padding: 20px 0px 20px 0px;
        }
        .team-heading-wrap {
            padding-top: 25px;
        }
        .team-details-full-wrap .col-md-4 {
            padding-left: 30px!important;
            padding-right: 30px !important;
        }
        .team-details-full-wrap .col-md-4 {
            padding-bottom: 10px;
        }
        .projects-residential-full-wrap .content-box,
        .projects-commercial-full-wrap .content-box {
            padding-top: 40px !important;
        }
        .mobile-reverse-row {
            display: flex;
            flex-direction: column-reverse;
        }
        #rise .paraf-2-gray {
            padding-top: 10px;
        }
        .box-sml-btn a {
            width: 80px !important;
            height: 20px !important;
            padding: 4px 0 0 0 !important;
        }
        .box-bottom-btn-wrap a,
        .box-bottom-btn-wrap button {
            font-size: 8px !important;
        }
        .box-lg-btn a {
            height: 40px !important;
        }
        .projects-residential-full-wrap .row .col-md-6.mobile-left-col,
        .projects-commercial-full-wrap .row .col-md-6.mobile-left-col {
            padding-left: 44px !important;
        }
        .mobile-left-col .content-right,
        .mobile-left-col .content-right p {
            float: left !important;
            text-align: left !important;
        }
        footer {
            padding-top: 40px !important;
            margin-top: 0px !important;
        }
        .img-bottom-description-wrap .paraf-1 {
            font-size: 14px !important;
        }
        .container.about-progress-wrap-text {
            margin-bottom: 20px !important;
        }
        .about-progress-wrap-text .pt-4 {
            padding-top: 1.5rem !important;
        }
        .custom-padding-right5 {
            padding-right: 10vw;
        }
        .news-full-wrap .banner-head1,
        .news-full-wrap .banner-head2 {
            margin-top: 0px !important;
        }
        .news-full-wrap .paraf-4 {
            font-size: 14px !important;
        }
        .news-full-wrap .justify-content-center {
            justify-content: left !important;
        }
        .news-full-wrap .header-content-box {
            padding: 40px 30px 40px 30px !important;
        }
        .custom-col-left0 {
            padding-left: 0px !important;
        }
        .news-header-row .heading-h1,
        .news-header-row .paraf-2-gray {
            text-align: left !important;
        }
        .tp-team-fullwrap .team-header1 {
            padding-left: 45px !important;
            padding-top: 25px !important;
        }
        .tp-team-fullwrap .col-md-6.content-box.team-header2 {
            padding-left: 10px !important;
            padding-bottom: 25px !important;
        }
        .team-middle-left-wrap {
            background-size: cover !important;
            background-position: right !important;
        }
        .team-middle-img-wrap {
            margin-bottom: 0px !important;
        }
        /* .footer {
            margin-top: 0px !important;
        } */
        .pro-info-right-con .pro-info-link-box {
            font-size: 15px !important;
        }
        .inner-more-projects .foter-container .heading-h2-foot {
            font-size: 24px;
            line-height: 18px;
        }
        .paraf-5-gray,
        footer p,
        footer ul li,
        footer ul li a,
        footer .links,
        .footer-createdby-wrap a {
            line-height: 22px;
        }
        .news-new-content-box .contenent-box {
            height: auto !important;
        }
        .award-slide-txt {
            line-height: 28px !important;
        }
        #carouselExampleCaptions img.award-wish-logoslide {
            margin-top: -2vh !important;
        }
        #carouselExampleCaptions .award-slide-caption {
            margin-top: -12%;
        }
        #carouselExampleCaptions .home-award-btn {
            margin-top: 8px !important;
        }
        /* .box-bottom-btn-wrap {
    
        } */
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {


        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }
       
        

        canvas {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            z-index: -1;
        }





        section.overview-section-2 .content-box {
            min-height: 365px !important;
            height: auto !important;
            max-width: 760px !important;
            padding-left: 50px !important;
            padding-top: 40px;
        }
        textarea#exampleFormControlTextarea1 {
            height: 56px !important;
        }
        /*sunanda*/
    }
    
    
    /* body.modal-open {
        overflow: hidden !important;
        position: fixed;
    } */
    
    
    /*sunanda*/
    
    @media only screen and (min-device-width: 428px) and (max-device-width: 767px) {

        
        .text h2 {
            color: #fff !important;
            width: 65% !important;
            margin: 0px auto !important;
            text-align: center !important;
            letter-spacing: 0 !important;
            text-shadow: 2px 2px 3px #000000e6 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            font-family: Raleway,sans-serif!important;
          }

        section.overview-section-2 .content-box {
            min-height: 335px !important;
            height: auto !important;
            max-width: 760px !important;
            padding-left: 50px !important;
            padding-top: 40px;
        }
        textarea#exampleFormControlTextarea1 {
            height: 56px !important;
        }
    }
    
    @media only screen and (min-width: 1367px) and (max-width: 1500px) {
        .heading-h1,
        .home-completed-project-title-wrap h1 {
            font-size: 42px !important;
            line-height: 72px !important;
        }
        .col-md-5.img-person {
            background-position: top !important;
        }
    }
    
    .panorama-viewport {
        position: relative;
        width: 600px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        text-align: left;
    }
    
    .panorama-container {
        position: relative;
        width: 100000px;
        margin: 0;
        padding: 0;
    }
    
    .panorama-control {
        position: absolute;
        display: none;
        padding: 0;
        margin: 0;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 30px;
        background: black;
        filter: alpha(opacity=50);
        opacity: .5;
        text-align: left;
    }
    
    .panorama-control-left,
    .panorama-control-right,
    .panorama-control-pause {
        float: left;
        padding: 0;
        margin: 0;
        color: white;
        text-decoration: none;
        font-size: 20px;
        line-height: 30px;
        width: 33%;
        text-align: center;
        font-family: 'Arial', sans-serif
    }
    
    .panorama-control-left:hover,
    .panorama-control-right:hover,
    .panorama-control-pause:hover {
        text-decoration: none;
    }
    
    .panorama-control-pause {
        text-transform: uppercase;
    }
    
    .panorama-panel {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        background: #009cff;
        filter: alpha(opacity=50);
        opacity: .5;
        color: white;
        padding: 4px;
    }
    
    .panorama-panel h2 {
        font-size: 11px;
        text-transform: uppercase;
        font-family: Arial, sans-serif;
        text-align: right;
    }
    
    .panorama-panel ul {
        display: none;
    }
    
    .panorama {
        position: relative;
        margin: 0;
        padding: 0;
        border: none;
    }
    
    .panorama-area {
        border: 2px solid white;
        filter: alpha(opacity=20);
        opacity: .2;
        background: black;
        font-size: 100%;
        cursor: pointer;
        text-decoration: none;
        color: white;
        text-align: right;
    }
    
    .panorama-area-hover {
        background: #0080e2;
        cursor: pointer;
    }
    
    .area0-hover {
        background: #ff7e00;
    }
    
    .area1-hover {
        background: #25bf5b;
    }
    
    .panorama-title {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        font-family: Verdana;
        background: gray;
        padding: 3px;
        filter: alpha(opacity=50);
        opacity: .5;
    }
    /* @import 'fancybox'; */
    
    
      
    
     
    
      
        </style>
    