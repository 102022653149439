<template>
  <div id="app">
    
    <Yowani />
  </div>
</template>

<script>
import Yowani from './components/Yowani.vue'

export default {
  name: 'App',
  components: {
    Yowani
  }
}
</script>

<style>

</style>
